:root {
    --mm-line-height: 20px;
    --mm-listitem-size: 44px;
    --mm-navbar-size: 44px;
    --mm-offset-top: 0;
    --mm-offset-right: 0;
    --mm-offset-bottom: 0;
    --mm-offset-left: 0;
    --mm-color-border: rgba(0, 0, 0, 0.1);
    --mm-color-button: rgba(0, 0, 0, 0.3);
    --mm-color-text: rgba(0, 0, 0, 0.75);
    --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
    --mm-color-background: #f3f3f3;
    --mm-color-background-highlight: rgba(0, 0, 0, 0.05);
    --mm-color-background-emphasis: rgba(255, 255, 255, 0.4);
    --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}

.mm-hidden {
    display: none !important
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative
}

.mm-menu {
    background: $white;
    border-color: var(--mm-color-border);
    color: var(--mm-color-text);
    line-height: var(--mm-line-height);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: absolute;
    top: var(--mm-offset-top);
    right: var(--mm-offset-right);
    bottom: var(--mm-offset-bottom);
    left: var(--mm-offset-left);
    z-index: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
    text-decoration: none
}

[dir=rtl] .mm-menu {
    direction: rtl
}

.mm-panel {
    background: $white;
    border-color: var(--mm-color-border);
    color: var(--mm-color-text);
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-panel:not(.mm-hidden) {
    display: block
}

.mm-panel:after {
    content: '';
    display: block;
    height: 20px
}

.mm-panel_opened {
    z-index: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-panel_opened-parent {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0)
}

.mm-panel_highest {
    z-index: 2
}

.mm-panel_noanimation {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important
}

.mm-panel_noanimation.mm-panel_opened-parent {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-panel__content {
    padding: 20px
}

.mm-panels {
    background: $white;
    border-color: var(--mm-color-border);
    color: var(--mm-color-text);
    position: relative;
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden
}

.mm-panels > .mm-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0
}

[dir=rtl] .mm-panel:not(.mm-panel_opened) {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

[dir=rtl] .mm-panel.mm-panel_opened-parent {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0)
}

.mm-listitem_vertical > .mm-panel {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    display: none;
    width: 100%;
    padding: 10px 0 10px 10px
}

.mm-listitem_vertical > .mm-panel:after,
.mm-listitem_vertical > .mm-panel:before {
    content: none;
    display: none
}

.mm-listitem_opened > .mm-panel {
    display: block
}

.mm-listitem_vertical > .mm-listitem__btn {
    height: 44px;
    height: var(--mm-listitem-size);
    bottom: auto
}

.mm-listitem_vertical .mm-listitem:last-child:after {
    border-color: transparent
}

.mm-listitem_opened > .mm-listitem__btn:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    right: 19px
}

.mm-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 44px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0
}

.mm-btn:after,
.mm-btn:before {
    border-color: var(--mm-color-button);
    border-width: 2px;
    border-style: solid
}

.mm-btn_next:after,
.mm-btn_prev:before {
    content: '';
    border-bottom: none;
    border-right: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0
}

.mm-btn_prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 23px;
    right: auto
}

.mm-btn_next:after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 23px;
    left: auto
}

.mm-btn_close:after,
.mm-btn_close:before {
    content: '';
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mm-btn_close:before {
    border-right: none;
    border-bottom: none;
    right: 18px
}

.mm-btn_close:after {
    border-left: none;
    border-top: none;
    right: 25px
}

[dir=rtl] .mm-btn_next:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 23px;
    right: auto
}

[dir=rtl] .mm-btn_prev:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 23px;
    left: auto
}

[dir=rtl] .mm-btn_close:after,
[dir=rtl] .mm-btn_close:before {
    right: auto
}

[dir=rtl] .mm-btn_close:before {
    left: 25px
}

[dir=rtl] .mm-btn_close:after {
    left: 18px
}

.mm-navbar {
    background: var(--mm-color-background);
    border-color: var(--mm-color-border);
    color: var(--mm-color-text-dimmed);
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--mm-navbar-size);
    opacity: 1;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.mm-navbar > * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    padding: calc((var(--mm-navbar-size) - var(--mm-line-height)) * .5);
    padding-left: 0;
    padding-right: 0
}

.mm-navbar a,
.mm-navbar a:hover {
    text-decoration: none
}

.mm-navbar__title {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%
}

.mm-navbar__btn {
    position: relative
}

.mm-navbar__btn:first-child {
    text-align: left
}

.mm-navbar__btn:last-child {
    text-align: right
}

[dir=rtl] .mm-navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

[dir=rtl] .mm-navbar__btn:first-child {
    text-align: right
}

[dir=rtl] .mm-navbar__btn:last-child {
    text-align: left
}

.mm-listview {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0
}

.mm-listitem {
    color: var(--mm-color-text);
    border-color: var(--mm-color-border);
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.mm-listitem:after {
    content: '';
    border-color: inherit;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 0
}

.mm-listitem a,
.mm-listitem a:hover {
    text-decoration: none
}

.mm-listitem__btn,
.mm-listitem__text {
    color: inherit;
    display: block;
    padding: calc((var(--mm-listitem-size) - var(--mm-line-height))/ 2);
    padding-left: 0;
    padding-right: 0
}

.mm-listitem__text {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 10px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%
}

.mm-listitem__btn {
    -webkit-tap-highlight-color: var(--mm-color-background-emphasis);
    tap-highlight-color: var(--mm-color-background-emphasis);
    background: rgba(3, 2, 1, 0);
    border-color: inherit;
    width: auto;
    padding-right: 54px;
    position: relative
}

.mm-listitem__btn:not(.mm-listitem__text) {
    border-left-width: 1px;
    border-left-style: solid
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
    background: var(--mm-color-background-highlight)
}

[dir=rtl] .mm-listitem:after {
    left: 0;
    right: 20px
}

[dir=rtl] .mm-listitem__text {
    padding-left: 10px;
    padding-right: 20px
}

[dir=rtl] .mm-listitem__btn {
    padding-left: 54px;
    border-left-width: 0;
    border-left-style: none
}

[dir=rtl] .mm-listitem__btn:not(.mm-listitem__text) {
    padding-right: 0;
    border-right-width: 1px;
    border-right-style: solid
}

.mm-page {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative
}

.mm-slideout {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    z-index: 1
}

.mm-wrapper_opened {
    overflow-x: hidden;
    position: relative
}

.mm-wrapper_background .mm-page {
    background: inherit
}

.mm-menu_offcanvas {
    position: fixed;
    right: auto;
    z-index: 0
}

.mm-menu_offcanvas:not(.mm-menu_opened) {
    display: none
}

.mm-menu_offcanvas {
    width: 80%;
    min-width: 240px;
    max-width: 440px
}

.mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:550px) {
    .mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0)
    }
}

.mm-wrapper__blocker {
    background: rgba(3, 2, 1, 0);
    overflow: hidden;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2
}

.mm-wrapper_blocking {
    overflow: hidden
}

.mm-wrapper_blocking body {
    overflow: hidden
}

.mm-wrapper_blocking .mm-wrapper__blocker {
    display: block
}

.mm-sronly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    width: 1px !important;
    min-width: 1px !important;
    height: 1px !important;
    min-height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important
}

.mm-menu_autoheight {
    -webkit-transition: none .4s ease;
    -o-transition: none .4s ease;
    transition: none .4s ease;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    -o-transition-property: transform, height;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform
}

.mm-menu_autoheight:not(.mm-menu_offcanvas) {
    position: relative
}

.mm-menu_autoheight.mm-menu_position-bottom,
.mm-menu_autoheight.mm-menu_position-top {
    max-height: 80%
}

.mm-menu_autoheight-measuring .mm-panel {
    display: block !important
}

.mm-menu_autoheight-measuring .mm-listitem_vertical:not(.mm-listitem_opened) .mm-panel {
    display: none !important
}

.mm-menu_autoheight-measuring .mm-panels > .mm-panel {
    bottom: auto !important;
    height: auto !important
}

[class*=mm-menu_columns-] {
    -webkit-transition-property: width;
    -o-transition-property: width;
    transition-property: width
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel {
    right: auto;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    -o-transition-property: width, transform;
    transition-property: width, transform;
    transition-property: width, transform, -webkit-transform
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_opened,
[class*=mm-menu_columns-] .mm-panels > .mm-panel_opened-parent {
    display: block !important
}

[class*=mm-panel_columns-] {
    border-right: 1px solid;
    border-color: inherit
}

.mm-menu_columns-1 .mm-panel_columns-0,
.mm-menu_columns-2 .mm-panel_columns-1,
.mm-menu_columns-3 .mm-panel_columns-2,
.mm-menu_columns-4 .mm-panel_columns-3 {
    border-right: none
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-0 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_columns-0 .mm-panels > .mm-panel {
    z-index: 0
}

.mm-menu_columns-0 .mm-panels > .mm-panel else {
    width: 100%
}

.mm-menu_columns-0 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu_columns-0 {
    width: 80%;
    min-width: 240px;
    max-width: 0
}

.mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:0px) {
    .mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:0px) {
    .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-1 {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu_columns-1 .mm-panels > .mm-panel {
    z-index: 1;
    width: 100%
}

.mm-menu_columns-1 .mm-panels > .mm-panel else {
    width: 100%
}

.mm-menu_columns-1 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0)
}

.mm-menu_columns-1 {
    width: 80%;
    min-width: 240px;
    max-width: 440px
}

.mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:550px) {
    .mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:550px) {
    .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-440px, 0, 0);
        transform: translate3d(-440px, 0, 0)
    }
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-2 {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0)
}

.mm-menu_columns-2 .mm-panels > .mm-panel {
    z-index: 2;
    width: 50%
}

.mm-menu_columns-2 .mm-panels > .mm-panel else {
    width: 100%
}

.mm-menu_columns-2 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0)
}

.mm-menu_columns-2 {
    width: 80%;
    min-width: 240px;
    max-width: 880px
}

.mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:1100px) {
    .mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
        -webkit-transform: translate3d(880px, 0, 0);
        transform: translate3d(880px, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:1100px) {
    .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-880px, 0, 0);
        transform: translate3d(-880px, 0, 0)
    }
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-3 {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0)
}

.mm-menu_columns-3 .mm-panels > .mm-panel {
    z-index: 3;
    width: 33.34%
}

.mm-menu_columns-3 .mm-panels > .mm-panel else {
    width: 100%
}

.mm-menu_columns-3 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
    -webkit-transform: translate3d(400%, 0, 0);
    transform: translate3d(400%, 0, 0)
}

.mm-menu_columns-3 {
    width: 80%;
    min-width: 240px;
    max-width: 1320px
}

.mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:1650px) {
    .mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
        -webkit-transform: translate3d(1320px, 0, 0);
        transform: translate3d(1320px, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:1650px) {
    .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-1320px, 0, 0);
        transform: translate3d(-1320px, 0, 0)
    }
}

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-4 {
    -webkit-transform: translate3d(400%, 0, 0);
    transform: translate3d(400%, 0, 0)
}

.mm-menu_columns-4 .mm-panels > .mm-panel {
    z-index: 4;
    width: 25%
}

.mm-menu_columns-4 .mm-panels > .mm-panel else {
    width: 100%
}

.mm-menu_columns-4 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
    -webkit-transform: translate3d(500%, 0, 0);
    transform: translate3d(500%, 0, 0)
}

.mm-menu_columns-4 {
    width: 80%;
    min-width: 240px;
    max-width: 1760px
}

.mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
        -webkit-transform: translate3d(240px, 0, 0);
        transform: translate3d(240px, 0, 0)
    }
}

@media all and (min-width:2200px) {
    .mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
        -webkit-transform: translate3d(1760px, 0, 0);
        transform: translate3d(1760px, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:2200px) {
    .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-1760px, 0, 0);
        transform: translate3d(-1760px, 0, 0)
    }
}

[class*=mm-menu_columns-].mm-menu_position-bottom,
[class*=mm-menu_columns-].mm-menu_position-top {
    width: 100%;
    max-width: 100%;
    min-width: 100%
}

.mm-wrapper_opening [class*=mm-menu_columns-].mm-menu_position-front {
    -webkit-transition-property: width, min-width, max-width, -webkit-transform;
    transition-property: width, min-width, max-width, -webkit-transform;
    -o-transition-property: width, min-width, max-width, transform;
    transition-property: width, min-width, max-width, transform;
    transition-property: width, min-width, max-width, transform, -webkit-transform
}

.mm-counter {
    color: var(--mm-color-text-dimmed);
    text-align: right;
    display: block;
    padding-left: 20px;
    float: right
}

.mm-listitem_nosubitems > .mm-counter {
    display: none
}

[dir=rtl] .mm-counter {
    text-align: left;
    float: left;
    padding-left: 0;
    padding-right: 20px
}

.mm-divider {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease;
    font-size: 75%;
    text-transform: uppercase;
    background: var(--mm-color-background);
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 0;
    min-height: var(--mm-line-height);
    padding: calc(((var(--mm-listitem-size) * .65) - var(--mm-line-height)) * .5);
    padding-right: 10px;
    padding-left: 20px
}

.mm-divider:before {
    content: '';
    background: var(--mm-color-background-highlight);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1
}

.mm-menu_dividers-light .mm-divider {
    font-size: inherit;
    color: var(--mm-color-text-dimmed);
    padding-top: calc((var(--mm-listitem-size) - var(--mm-line-height)) * .75);
    padding-bottom: calc((var(--mm-listitem-size) - var(--mm-line-height)) * .25)
}

.mm-menu_dividers-light .mm-divider:before {
    background: inherit
}

.mm-menu_border-none .mm-divider {
    border-top-width: 1px;
    border-top-style: solid
}

.mm-wrapper_dragging .mm-menu,
.mm-wrapper_dragging .mm-slideout {
    -webkit-transition-duration: 0s !important;
    -o-transition-duration: 0s !important;
    transition-duration: 0s !important
}

.mm-menu_dropdown {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    height: 80%
}

.mm-wrapper_dropdown .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

.mm-wrapper_dropdown .mm-wrapper__blocker {
    -webkit-transition-delay: 0s !important;
    -o-transition-delay: 0s !important;
    transition-delay: 0s !important;
    z-index: 1
}

.mm-wrapper_dropdown .mm-menu_dropdown {
    z-index: 2
}

.mm-wrapper_dropdown.mm-wrapper_opened:not(.mm-wrapper_opening) .mm-menu_dropdown {
    display: none
}

[class*=mm-menu_tip-]:before {
    content: '';
    background: inherit;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mm-menu_tip-left:before {
    left: 22px
}

.mm-menu_tip-right:before {
    right: 22px
}

.mm-menu_tip-top:before {
    top: -8px
}

.mm-menu_tip-bottom:before {
    bottom: -8px
}

:root {
    --mm-iconbar-size: 44px
}

.mm-menu_iconbar-left .mm-navbars_bottom,
.mm-menu_iconbar-left .mm-navbars_top,
.mm-menu_iconbar-left .mm-panels {
    margin-left: var(--mm-iconbar-size)
}

.mm-menu_iconbar-left .mm-iconbar {
    border-right-width: 1px;
    display: block;
    left: 0
}

.mm-menu_iconbar-right .mm-navbars_bottom,
.mm-menu_iconbar-right .mm-navbars_top,
.mm-menu_iconbar-right .mm-panels {
    margin-right: var(--mm-iconbar-size)
}

.mm-menu_iconbar-right .mm-iconbar {
    border-left-width: 1px;
    display: block;
    right: 0
}

.mm-iconbar {
    background: var(--mm-color-background);
    color: var(--mm-color-text-dimmed);
    border: 0 solid rgba(0, 0, 0, .1);
    border-color: var(--mm-color-border);
    text-align: center;
    overflow: hidden;
    display: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: var(--mm-iconbar-size);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2
}

.mm-iconbar__bottom,
.mm-iconbar__top {
    width: inherit;
    position: absolute
}

.mm-iconbar__bottom > *,
.mm-iconbar__top > * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    padding: 11px 0
}

.mm-iconbar__bottom a,
.mm-iconbar__bottom a:hover,
.mm-iconbar__top a,
.mm-iconbar__top a:hover {
    text-decoration: none
}

.mm-iconbar__top {
    top: 0
}

.mm-iconbar__bottom {
    bottom: 0
}

.mm-iconbar__tab_selected {
    background: var(--mm-color-background-emphasis)
}

:root {
    --mm-iconpanel-size: 44px
}

.mm-panel_iconpanel-1 {
    width: calc(100% - (var(--mm-iconpanel-size) * 1))
}

.mm-panel_iconpanel-2 {
    width: calc(100% - (var(--mm-iconpanel-size) * 2))
}

.mm-panel_iconpanel-3 {
    width: calc(100% - (var(--mm-iconpanel-size) * 3))
}

.mm-panel_iconpanel-first ~ .mm-panel {
    width: calc(100% - var(--mm-iconpanel-size))
}

.mm-menu_iconpanel .mm-panels > .mm-panel {
    left: auto;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    -o-transition-property: transform, width;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform
}

.mm-menu_iconpanel .mm-panels > .mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
    border-left-width: 1px;
    border-left-style: solid
}

.mm-menu_iconpanel .mm-panels > .mm-panel_opened,
.mm-menu_iconpanel .mm-panels > .mm-panel_opened-parent {
    display: block !important
}

.mm-menu_iconpanel .mm-panels > .mm-panel_opened-parent {
    overflow-y: hidden;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset
}

.mm-menu_hidedivider .mm-panel_opened-parent .mm-divider,
.mm-menu_hidenavbar .mm-panel_opened-parent .mm-navbar {
    opacity: 0
}

.mm-panel__blocker {
    background: inherit;
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.mm-panel_opened-parent .mm-panel__blocker {
    opacity: .6;
    bottom: -100000px
}

[dir=rtl] .mm-menu_iconpanel .mm-panels > .mm-panel {
    left: 0;
    right: auto;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    -o-transition-property: transform, width;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform
}

[dir=rtl] .mm-menu_iconpanel .mm-panels > .mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
    border-left: none;
    border-right: 1px solid;
    border-color: inherit
}

.mm-menu_keyboardfocus a:focus,
.mm-menu_keyboardfocus.mm-menu_opened ~ .mm-wrapper__blocker a:focus {
    outline: 0;
    background: var(--mm-color-background-emphasis)
}

.mm-wrapper__blocker .mm-tabstart {
    cursor: default;
    display: block;
    width: 100%;
    height: 100%
}

.mm-wrapper__blocker .mm-tabend {
    opacity: 0;
    position: absolute;
    bottom: 0
}

.mm-navbars_bottom,
.mm-navbars_top {
    background: inherit;
    border-color: inherit;
    border-width: 0;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.mm-navbars_bottom > .mm-navbar,
.mm-navbars_top > .mm-navbar {
    border-width: 0
}

.mm-navbars_bottom > .mm-navbar > :not(img):not(.mm-btn),
.mm-navbars_top > .mm-navbar > :not(img):not(.mm-btn) {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.mm-navbars_top {
    border-bottom-style: solid;
    border-bottom-width: 1px
}

.mm-navbars_bottom {
    border-top-style: solid;
    border-top-width: 1px
}

.mm-navbar__breadcrumbs {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    padding: 0
}

.mm-navbar__breadcrumbs > * {
    display: inline-block;
    padding: calc((var(--mm-navbar-size) - var(--mm-line-height)) * .5);
    padding-left: 0;
    padding-right: 6px
}

.mm-navbar__breadcrumbs > a {
    text-decoration: underline
}

.mm-btn.mm-hidden + .mm-navbar__breadcrumbs {
    padding-left: 20px
}

.mm-navbar__tab_selected {
    background: inherit !important;
    color: inherit !important;
    border-width: 1px;
    border-style: solid;
    border-color: inherit
}

.mm-navbar__tab_selected:first-child {
    border-left: none
}

.mm-navbar__tab_selected:last-child {
    border-right: none
}

.mm-navbars_top.mm-navbars_has-tabs .mm-navbar_tabs,
.mm-navbars_top.mm-navbars_has-tabs .mm-navbar_tabs ~ .mm-navbar {
    background: inherit !important;
    color: inherit !important
}

.mm-navbars_top .mm-navbar_tabs:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid
}

.mm-navbars_top .mm-navbar__tab_selected {
    border-bottom: none;
    margin-bottom: -1px
}

.mm-navbars_top .mm-navbar_tabs:first-child .mm-navbar__tab_selected {
    border-top: none
}

.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar {
    background: inherit;
    color: inherit
}

.mm-navbars_bottom .mm-navbar_tabs:not(:first-child) {
    border-top-width: 1px;
    border-top-style: solid
}

.mm-navbars_bottom .mm-navbar__tab_selected {
    border-top: none;
    margin-top: -1px
}

.mm-navbars_bottom .mm-navbar_tabs:last-child .mm-navbar__tab_selected {
    border-bottom: none
}

.mm-navbar_tabs > a:not(.mm-navbar__tab_selected),
.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar_tabs ~ .mm-navbar,
.mm-navbars_top.mm-navbars_has-tabs > .mm-navbar:not(.mm-navbar_tabs) {
    background: var(--mm-color-background-emphasis);
    color: var(--mm-color-text-dimmed)
}

.mm-searchfield {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--mm-navbar-size);
    padding: 0;
    overflow: hidden
}

.mm-searchfield input {
    border: none !important;
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 4px;
    font: inherit;
    font-size: inherit;
    line-height: calc(var(--mm-navbar-size) * .7);
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: calc(var(--mm-navbar-size) * .7);
    min-height: unset;
    max-height: unset;
    margin: 0;
    padding: 0 10px
}

.mm-searchfield input,
.mm-searchfield input:focus,
.mm-searchfield input:hover {
    background: var(--mm-color-background-highlight);
    color: var(--mm-color-text)
}

.mm-searchfield input::-ms-clear {
    display: none
}

.mm-searchfield__input {
    padding: 6.6px 10px 0 10px;
    padding-top: calc(var(--mm-navbar-size) * .15);
    position: relative
}

.mm-panel__noresultsmsg {
    color: var(--mm-color-text-dimmed);
    text-align: center;
    font-size: 150%;
    padding: 44px 0
}

.mm-searchfield__btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0
}

.mm-panel_search {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    border-left: none !important
}

.mm-searchfield__input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%
}

.mm-searchfield__cancel {
    line-height: var(--mm-navbar-size);
    text-decoration: none;
    display: block;
    padding-right: 10px;
    margin-right: -100px;
    -webkit-transition: margin .4s ease;
    -o-transition: margin .4s ease;
    transition: margin .4s ease
}

.mm-searchfield__cancel-active {
    margin-right: 0
}

.mm-listitem_nosubitems > .mm-listitem__btn {
    display: none
}

.mm-listitem_nosubitems > .mm-listitem__text {
    padding-right: 10px
}

.mm-sectionindexer {
    background: inherit;
    text-align: center;
    font-size: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    z-index: 5;
    -webkit-transition: right .4s ease;
    -o-transition: right .4s ease;
    transition: right .4s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.mm-sectionindexer a {
    color: var(--mm-color-text-dimmed);
    line-height: 1;
    text-decoration: none;
    display: block
}

.mm-sectionindexer ~ .mm-panel {
    padding-right: 0;
    -webkit-transition: padding-right .4s ease;
    -o-transition: padding-right .4s ease;
    transition: padding-right .4s ease
}

.mm-sectionindexer_active {
    right: 0
}

.mm-sectionindexer_active ~ .mm-panel {
    padding-right: 20px
}

:root {
    --mm-sidebar-collapsed-size: 44px;
    --mm-sidebar-expanded-size: 440px
}

.mm-wrapper_sidebar-collapsed body,
.mm-wrapper_sidebar-expanded body {
    position: relative
}

.mm-wrapper_sidebar-collapsed .mm-slideout,
.mm-wrapper_sidebar-expanded .mm-slideout {
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    -o-transition-property: width, transform;
    transition-property: width, transform;
    transition-property: width, transform, -webkit-transform
}

.mm-wrapper_sidebar-collapsed .mm-page,
.mm-wrapper_sidebar-expanded .mm-page {
    background: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 100vh
}

.mm-wrapper_sidebar-collapsed .mm-menu_sidebar-collapsed,
.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    top: 0 !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important
}

.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidedivider .mm-divider,
.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidenavbar .mm-navbar {
    opacity: 0
}

.mm-wrapper_sidebar-collapsed .mm-slideout {
    width: calc(100% - var(--mm-sidebar-collapsed-size));
    -webkit-transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0);
    transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0)
}

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
    border-right-width: 1px;
    border-right-style: solid;
    min-width: 0 !important;
    max-width: 100000px !important
}

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded.mm-menu_pageshadow:after {
    content: none;
    display: none
}

.mm-wrapper_sidebar-expanded.mm-wrapper_blocking,
.mm-wrapper_sidebar-expanded.mm-wrapper_blocking body {
    overflow: visible
}

.mm-wrapper_sidebar-expanded .mm-wrapper__blocker {
    display: none !important
}

.mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened ~ .mm-slideout {
    width: calc(100% - var(--mm-sidebar-expanded-size));
    -webkit-transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0);
    transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0)
}

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
    width: var(--mm-sidebar-expanded-size)
}

.mm-menu__blocker {
    background: rgba(3, 2, 1, 0);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3
}

.mm-menu_opened .mm-menu__blocker {
    display: none
}

[dir=rtl].mm-wrapper_sidebar-collapsed .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

[dir=rtl].mm-wrapper_sidebar-expanded .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

[dir=rtl].mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

input.mm-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background: var(--mm-color-border);
    border-radius: 34px;
    display: inline-block;
    min-width: 58px;
    width: 58px;
    height: 34px;
    margin: 0 10px;
    margin-top: calc((var(--mm-listitem-size) - 34px)/ 2);
    -webkit-transition: background-color .2s ease;
    -o-transition: background-color .2s ease;
    transition: background-color .2s ease
}

input.mm-toggle:before {
    content: '';
    background: var(--mm-color-background);
    border-radius: 34px;
    display: block;
    width: 32px;
    height: 32px;
    margin: 1px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease
}

input.mm-toggle:checked {
    background: #4bd963
}

input.mm-toggle:checked:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px)
}

input.mm-check {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: 0 10px;
    margin-top: calc((var(--mm-listitem-size) - 34px)/ 2)
}

input.mm-check:before {
    border-color: var(--mm-color-text);
    content: '';
    display: block;
    border-left: 3px solid;
    border-bottom: 3px solid;
    width: 40%;
    height: 20%;
    margin: 25% 0 0 20%;
    opacity: .3;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease
}

input.mm-check:checked:before {
    opacity: 1
}

[dir=rtl] input.mm-toggle:checked ~ label.mm-toggle:before {
    float: left
}

.mm-menu_border-none .mm-listitem:after,
.mm-panel_border-none .mm-listitem:after {
    content: none
}

.mm-menu_border-full .mm-listitem:after,
.mm-panel_border-full .mm-listitem:after {
    left: 0 !important
}

.mm-menu_border-offset .mm-listitem:after,
.mm-panel_border-offset .mm-listitem:after {
    right: 20px
}

.mm-menu_fx-menu-zoom {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-wrapper_opened .mm-menu_fx-menu-zoom {
    -webkit-transform: scale(.7, .7) translate3d(-30%, 0, 0);
    transform: scale(.7, .7) translate3d(-30%, 0, 0);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center
}

.mm-wrapper_opening .mm-menu_fx-menu-zoom {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

.mm-wrapper_opened .mm-menu_fx-menu-zoom.mm-menu_position-right {
    -webkit-transform: scale(.7, .7) translate3d(30%, 0, 0);
    transform: scale(.7, .7) translate3d(30%, 0, 0);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center
}

.mm-wrapper_opening .mm-menu_fx-menu-zoom.mm-menu_position-right {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

.mm-menu_fx-menu-slide {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-wrapper_opened .mm-menu_fx-menu-slide {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0)
}

.mm-wrapper_opening .mm-menu_fx-menu-slide {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-wrapper_opened .mm-menu_fx-menu-slide.mm-menu_position-right {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0)
}

.mm-wrapper_opening .mm-menu_fx-menu-slide.mm-menu_position-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_fx-menu-fade {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.mm-wrapper_opening .mm-menu_fx-menu-fade {
    opacity: 1
}

.mm-menu_fx-panels-none .mm-panel,
.mm-panel_fx-none {
    -webkit-transition-property: none;
    -o-transition-property: none;
    transition-property: none
}

.mm-menu_fx-panels-none .mm-panel.mm-panel_opened-parent,
.mm-panel_fx-none.mm-panel_opened-parent {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-zoom .mm-panel,
.mm-panel_fx-zoom {
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
    transform: scale(1.5, 1.5) translate3d(100%, 0, 0)
}

.mm-menu_fx-panels-zoom .mm-panel.mm-panel_opened,
.mm-panel_fx-zoom.mm-panel_opened {
    -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
    transform: scale(1, 1) translate3d(0, 0, 0)
}

.mm-menu_fx-panels-zoom .mm-panel.mm-panel_opened-parent,
.mm-panel_fx-zoom.mm-panel_opened-parent {
    -webkit-transform: scale(.7, .7) translate3d(-30%, 0, 0);
    transform: scale(.7, .7) translate3d(-30%, 0, 0)
}

.mm-menu_fx-panels-slide-0 .mm-panel_opened-parent,
.mm-panel_fx-slide-0.mm-panel_opened-parent {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-slide-100 .mm-panel_opened-parent,
.mm-panel_fx-slide-100.mm-panel_opened-parent {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.mm-menu_fx-panels-slide-up .mm-panel,
.mm-panel_fx-slide-up {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.mm-menu_fx-panels-slide-up .mm-panel_opened,
.mm-menu_fx-panels-slide-up .mm-panel_opened-parent,
.mm-panel_fx-slide-up.mm-panel_opened {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-slide-right .mm-panel,
.mm-panel_fx-slide-right {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.mm-menu_fx-panels-slide-right .mm-panel_opened,
.mm-menu_fx-panels-slide-right .mm-panel_opened-parent,
.mm-panel_fx-slide-right.mm-panel_opened {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

[class*=mm-menu_fx-listitems-] .mm-listitem {
    -webkit-transition: none .4s ease;
    -o-transition: none .4s ease;
    transition: none .4s ease
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(1) {
    -webkit-transition-delay: 50ms;
    -o-transition-delay: 50ms;
    transition-delay: 50ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(2) {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(3) {
    -webkit-transition-delay: 150ms;
    -o-transition-delay: 150ms;
    transition-delay: 150ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(4) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(5) {
    -webkit-transition-delay: 250ms;
    -o-transition-delay: 250ms;
    transition-delay: 250ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(6) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(7) {
    -webkit-transition-delay: 350ms;
    -o-transition-delay: 350ms;
    transition-delay: 350ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(8) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(9) {
    -webkit-transition-delay: 450ms;
    -o-transition-delay: 450ms;
    transition-delay: 450ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(10) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(11) {
    -webkit-transition-delay: 550ms;
    -o-transition-delay: 550ms;
    transition-delay: 550ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(12) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(13) {
    -webkit-transition-delay: 650ms;
    -o-transition-delay: 650ms;
    transition-delay: 650ms
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(14) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s
}

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(15) {
    -webkit-transition-delay: 750ms;
    -o-transition-delay: 750ms;
    transition-delay: 750ms
}

.mm-menu_fx-listitems-slide .mm-listitem {
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    opacity: 0
}

.mm-wrapper_opening .mm-menu_fx-listitems-slide .mm-panel_opened .mm-listitem {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.mm-menu_fx-listitems-fade .mm-listitem {
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    opacity: 0
}

.mm-wrapper_opening .mm-menu_fx-listitems-fade .mm-panel_opened .mm-listitem {
    opacity: 1
}

.mm-menu_fx-listitems-drop .mm-listitem {
    -webkit-transition-property: opacity, top;
    -o-transition-property: opacity, top;
    transition-property: opacity, top;
    opacity: 0;
    top: -25%
}

.mm-wrapper_opening .mm-menu_fx-listitems-drop .mm-panel_opened .mm-listitem {
    opacity: 1;
    top: 0
}

.mm-menu_fullscreen {
    width: 100%;
    min-width: 140px;
    max-width: 10000px
}

.mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
    -webkit-transform: translate3d(100vw, 0, 0);
    transform: translate3d(100vw, 0, 0)
}

@media all and (max-width:140px) {
    .mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0)
    }
}

@media all and (min-width:10000px) {
    .mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
        -webkit-transform: translate3d(10000px, 0, 0);
        transform: translate3d(10000px, 0, 0)
    }
}

.mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:140px) {
    .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-140px, 0, 0);
        transform: translate3d(-140px, 0, 0)
    }
}

@media all and (min-width:10000px) {
    .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-10000px, 0, 0);
        transform: translate3d(-10000px, 0, 0)
    }
}

.mm-menu_fullscreen.mm-menu_position-top {
    height: 100vh;
    min-height: 140px;
    max-height: 10000px
}

.mm-menu_fullscreen.mm-menu_position-bottom {
    height: 100vh;
    min-height: 140px;
    max-height: 10000px
}

.mm-menu_listview-justify .mm-panels > .mm-panel:after,
.mm-menu_listview-justify .mm-panels > .mm-panel:before,
.mm-panels > .mm-panel_listview-justify:after,
.mm-panels > .mm-panel_listview-justify:before {
    content: none;
    display: none
}

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listview,
.mm-panels > .mm-panel_listview-justify .mm-listview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listitem,
.mm-panels > .mm-panel_listview-justify .mm-listitem {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    min-height: 44px
}

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listitem__text,
.mm-panels > .mm-panel_listview-justify .mm-listitem__text {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.mm-listview_inset {
    list-style: inside disc;
    width: 100%;
    padding: 0 30px 15px 30px;
    margin: 0
}

.mm-listview_inset .mm-listitem {
    padding: 5px 0
}

.mm-listitem_spacer {
    padding-top: 44px;
    padding-top: var(--mm-listitem-size)
}

.mm-listitem_spacer > .mm-listitem__btn {
    top: 44px;
    top: var(--mm-listitem-size)
}

.mm-menu_multiline .mm-listitem__text,
.mm-panel_multiline .mm-listitem__text {
    -o-text-overflow: clip;
    text-overflow: clip;
    white-space: normal
}

[class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
    opacity: 0
}

.mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
    opacity: .3;
    -webkit-transition: opacity .4s ease .4s;
    -o-transition: opacity .4s ease .4s;
    transition: opacity .4s ease .4s
}

.mm-menu_opened.mm-menu_pagedim ~ .mm-wrapper__blocker {
    background: inherit
}

.mm-menu_opened.mm-menu_pagedim-black ~ .mm-wrapper__blocker {
    background: #000
}

.mm-menu_opened.mm-menu_pagedim-white ~ .mm-wrapper__blocker {
    background: #fff
}

.mm-menu_popup {
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease;
    opacity: 0;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    height: 80%;
    min-height: 140px;
    max-height: 880px;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    z-index: 2;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0)
}

.mm-menu_popup.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

.mm-menu_popup.mm-menu_opened ~ .mm-wrapper__blocker {
    -webkit-transition-delay: 0s !important;
    -o-transition-delay: 0s !important;
    transition-delay: 0s !important;
    z-index: 1
}

.mm-wrapper_opening .mm-menu_popup {
    opacity: 1
}

.mm-menu_position-right {
    left: auto;
    right: 0
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width:300px) {
    .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-240px, 0, 0);
        transform: translate3d(-240px, 0, 0)
    }
}

@media all and (min-width:550px) {
    .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
        -webkit-transform: translate3d(-440px, 0, 0);
        transform: translate3d(-440px, 0, 0)
    }
}

.mm-menu_position-bottom,
.mm-menu_position-front,
.mm-menu_position-top {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-menu_position-bottom.mm-menu_opened,
.mm-menu_position-front.mm-menu_opened,
.mm-menu_position-top.mm-menu_opened {
    z-index: 2
}

.mm-menu_position-bottom.mm-menu_opened ~ .mm-slideout,
.mm-menu_position-front.mm-menu_opened ~ .mm-slideout,
.mm-menu_position-top.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    z-index: 0
}

.mm-menu_position-bottom.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-front.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-top.mm-menu_opened ~ .mm-wrapper__blocker {
    z-index: 1
}

.mm-menu_position-front {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.mm-menu_position-front.mm-menu_position-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.mm-menu_position-bottom,
.mm-menu_position-top {
    width: 100%;
    min-width: 100%;
    max-width: 100%
}

.mm-menu_position-top {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.mm-menu_position-top {
    height: 80vh;
    min-height: 140px;
    max-height: 880px
}

.mm-menu_position-bottom {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    top: auto
}

.mm-menu_position-bottom {
    height: 80vh;
    min-height: 140px;
    max-height: 880px
}

.mm-wrapper_opening .mm-menu_position-bottom,
.mm-wrapper_opening .mm-menu_position-front,
.mm-wrapper_opening .mm-menu_position-top {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mm-menu_shadow-page:after {
    -webkit-box-shadow: var(--mm-shadow);
    box-shadow: var(--mm-shadow);
    content: "";
    display: block;
    width: 20px;
    height: 120%;
    position: absolute;
    left: 100%;
    top: -10%;
    z-index: 100;
    -webkit-clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);
    clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%)
}

.mm-menu_shadow-page.mm-menu_position-right:after {
    left: auto;
    right: 100%;
    -webkit-clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%);
    clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%)
}

.mm-menu_shadow-page.mm-menu_position-front:after {
    content: none;
    display: none
}

.mm-menu_shadow-menu {
    -webkit-box-shadow: var(--mm-shadow);
    box-shadow: var(--mm-shadow)
}

.mm-menu_shadow-panels .mm-panels > .mm-panel {
    -webkit-box-shadow: var(--mm-shadow);
    box-shadow: var(--mm-shadow)
}

.mm-menu_theme-white {
    --mm-color-border: rgba(0, 0, 0, 0.1);
    --mm-color-button: rgba(0, 0, 0, 0.3);
    --mm-color-text: rgba(0, 0, 0, 0.7);
    --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
    --mm-color-background: #fff;
    --mm-color-background-highlight: rgba(0, 0, 0, 0.06);
    --mm-color-background-emphasis: rgba(0, 0, 0, 0.03);
    --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.mm-menu_theme-dark {
    --mm-color-border: rgba(0, 0, 0, 0.3);
    --mm-color-button: rgba(255, 255, 255, 0.4);
    --mm-color-text: rgba(255, 255, 255, 0.85);
    --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
    --mm-color-background: #333;
    --mm-color-background-highlight: rgba(255, 255, 255, 0.08);
    --mm-color-background-emphasis: rgba(0, 0, 0, 0.1);
    --mm-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
}

.mm-menu_theme-black {
    --mm-color-border: rgba(255, 255, 255, 0.25);
    --mm-color-button: rgba(255, 255, 255, 0.4);
    --mm-color-text: rgba(255, 255, 255, 0.75);
    --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
    --mm-color-background: #000;
    --mm-color-background-highlight: rgba(255, 255, 255, 0.2);
    --mm-color-background-emphasis: rgba(255, 255, 255, 0.15);
    --mm-shadow: none
}

.mm-menu_tileview .mm-listview,
.mm-panel_tileview .mm-listview {
    margin: 0 !important
}

.mm-menu_tileview .mm-listview:after,
.mm-panel_tileview .mm-listview:after {
    content: '';
    display: block;
    clear: both
}

.mm-menu_tileview .mm-listitem,
.mm-panel_tileview .mm-listitem {
    padding: 0;
    float: left;
    position: relative;
    width: 50%;
    height: 0;
    padding-top: 50%
}

.mm-menu_tileview .mm-listitem:after,
.mm-panel_tileview .mm-listitem:after {
    left: 0;
    top: 0;
    border-right-width: 1px;
    border-right-style: solid;
    z-index: -1
}

.mm-menu_tileview .mm-listitem.mm-tile-xs,
.mm-panel_tileview .mm-listitem.mm-tile-xs {
    width: 12.5%;
    padding-top: 12.5%
}

.mm-menu_tileview .mm-listitem.mm-tile-s,
.mm-panel_tileview .mm-listitem.mm-tile-s {
    width: 25%;
    padding-top: 25%
}

.mm-menu_tileview .mm-listitem.mm-tile-l,
.mm-panel_tileview .mm-listitem.mm-tile-l {
    width: 75%;
    padding-top: 75%
}

.mm-menu_tileview .mm-listitem.mm-tile-xl,
.mm-panel_tileview .mm-listitem.mm-tile-xl {
    width: 100%;
    padding-top: 100%
}

.mm-menu_tileview .mm-listitem__text,
.mm-panel_tileview .mm-listitem__text {
    line-height: 1px;
    text-align: center;
    padding: 50% 10px 0 10px;
    margin: 0;
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 1px;
    left: 0
}

.mm-menu_tileview .mm-listitem__btn,
.mm-panel_tileview .mm-listitem__btn {
    width: auto
}

.mm-menu_tileview .mm-listitem__btn:after,
.mm-menu_tileview .mm-listitem__btn:before,
.mm-panel_tileview .mm-listitem__btn:after,
.mm-panel_tileview .mm-listitem__btn:before {
    content: none;
    display: none
}

.mm-menu_tileview .mm-divider,
.mm-panel_tileview .mm-divider {
    display: none
}

.mm-menu_tileview .mm-panel,
.mm-panel_tileview {
    padding-left: 0;
    padding-right: 0
}

.mm-menu_tileview .mm-panel:after,
.mm-menu_tileview .mm-panel:before,
.mm-panel_tileview:after,
.mm-panel_tileview:before {
    content: none;
    display: none
}

body.modal-open .mm-slideout {
    z-index: unset
}
