.first-screen {
    padding: 33vh 0 15vh;
    min-height: 100vh;

    p {
        margin-bottom: 48px;
        font-size: 20px;
        opacity: .5;
    }
}

.about-block {
    background: linear-gradient(121.59deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%);

    &:before {
        bottom: 0;
        right: 0;
        width: 408px;
        height: 261px;
        background-image: url("data:image/svg+xml,%3Csvg width='408' height='261' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48.464 255.682C30.24 251.045-.368 234.054 3.302 200.045 8.057 156 52.363 138.284 65.102 130.5 81.74 120.333 105.51 105 81.74 81.818c-9.507 8.5-20.441 29.673 11.885 46.364 40.407 20.863 68.93 44.045 68.93 76.5 0 30.136-28.523 53.318-40.407 53.318-11.885 0-54.305-24.776-49.915-71.864 4.754-51 45.161-85.772 73.684-104.318 28.523-18.545 66.553-43.877 66.553-60.272C212.47 7.396 200.586 3 200.586 3s-11.884 4.636-11.884 18.546c0 13.909 33.007 33.906 71.307 60.272 40.408 27.818 68.93 74.182 68.93 106.637 0 32.454-28.522 67.227-45.161 67.227-11.884 2.318-30.9-19.865-30.9-44.046 0-32.454 42.784-60.272 68.93-69.545 20.917-7.418 16.639-23.182 7.131-30.136-9.507 6.954-16.163 21.79 14.261 34.772 38.031 16.228 61.8 41.728 61.8 64.909 0 23.182-16.638 34.773-28.523 44.046' stroke='%23D7D7D7' stroke-opacity='.4' stroke-width='4.5' stroke-linecap='square' stroke-linejoin='bevel'/%3E%3C/svg%3E");
    }

    .text {
        max-width: 640px;
    }

    p {
        font-weight: bolder;

        &:last-child {
            margin-bottom: 0;
            font-weight: 400;
        }
    }
}

.theater {
    background: linear-gradient(97.1deg, #69380D 0.71%, #3C1C00 103.42%);

    .text {

       min-height: 210px;
        z-index: 1;

        &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background-image: url(../img/frame-w-up.png);
            background-repeat: no-repeat;
        }

        &:after {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background-image: url(../img/frame-w-dw.png);
            background-repeat: no-repeat;
        }
    }

    .wrap {
        left: -30px;
        width: calc(100% - 33.6px);
        z-index: -1;

        &:before {
            top: 56px;
            left: 36px;
            width: 3px;
            height: calc(100% - 208px);
            background-image: url(../img/frame-w-l.png);
        }

        &:after {
            top: 56px;
            right: 0;
            width: 3px;
            height: calc(100% - 208px);
            background-image: url(../img/frame-w-l.png);
        }
    }


    p {
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.video-wrap {
    min-height: 760px;
}

.blog-block {

    .category {
        border-radius: 50px;
    }

    .literature {
        background: rgba(174, 148, 30, 0.08);

        a {
            color: $yellow2;
        }
    }

    .history {
        background: rgba(215, 215, 215, 0.28);

        a {
            color: $gray-600;
        }
    }

    .art {
        background: rgba(172, 138, 90, 0.2);

        a {
            color: $orange;
        }
    }

    p {
        margin-bottom: 0;
        display: inline;
        font-weight: lighter;
        color: $gray-600;
    }

    h3 {

        a {
            color: $text;

            &:hover {
                color: $yellow;
            }
        }

    }

    .item {

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

    }

    .img-wrap {
        min-width: 200px;
        width: 200px;
        height: 170px;

        /*img {
            width: 200px;
            height: 170px;
        }*/
    }
}

.info-block {

    .text-wrap {
        min-height: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;

        &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background-image: url(../img/frame-w-up.png);
            background-repeat: no-repeat;
        }

        &:after {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background-image: url(../img/frame-w-dw.png);
            background-repeat: no-repeat;
        }
    }

    .wrap {
        left: -30px;
        width: calc(100% - 33.6px);
        z-index: -1;

        &:before {
            top: 104px;
            left: 36px;
            width: 3px;
            height: calc(100% - 208px);
            background-image: url(../img/frame-w-l.png);
        }

        &:after {
            top: 104px;
            right: 0;
            width: 3px;
            height: calc(100% - 208px);
            background-image: url(../img/frame-w-l.png);
        }
    }


    .text {
        top: 0;
        left: 0;
        background: rgba(172, 138, 90, 0.8);
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
        letter-spacing: 0.05em;
        opacity: 0;
        transition: .25s ease-in-out;
    }

    .img-wrap {
        &:hover {
            .text {
                opacity: 1;
            }
        }
    }

    p {
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
        font-size: 18px;

        &:last-child {
            margin-bottom: 0;
            font-size: 16px;
        }
    }

    .row {
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

}

.clubs-block {
    background: linear-gradient(119.48deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%);

    &:before {
        right: 0;
        bottom: 50px;
        width: 398px;
        height: 86px;
        background-image: url("data:image/svg+xml,%3Csvg width='398' height='86' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 31.929C8.122 13.706 32.15 1.184 51.76.099c20.678-1.155 40.306 7.99 56.059 20.818 16.819 13.285 30.457 29.846 48.207 42.164 16.735 11.588 32.15 21.956 53.013 18.664 18.071-2.834 39.967-15.491 36.092-36.497-2.826-15.236-19.814-41.18-38.173-38.465-14.603 1.9-5.212 18.393.812 25.451 13.824 16 35.534 23.755 56.312 22.753 15.5-.78 30.356-6.09 43.994-13.404a186.095 186.095 0 008.731-5.005c7.344 8.89 16.329 9.773 23.909 8.399 14.772-2.664 29.832-13.727 45.247-11.148 11.032 1.833 16.447 12.386 7.665 24.484 5.364-12.335-1.591-26.707-29.273-16.39-15.787 5.887-37.547 14.473-47.615-5.006-6.126 6.024-16.075 12.132-23.012 15.542a87.808 87.808 0 01-30.323 8.79c-20.101 1.883-42.911-2.122-58.105-15.577-8.935-7.923-21.997-29.896-9.442-41.01 15.888-14.066 35.753 7.55 42.995 20.497 4.552 8.11 8.004 17.748 7.243 27.148-.88 10.977-8.19 18.664-17.023 24.025a71.955 71.955 0 01-62.556 4.598c-19.966-7.787-37.496-24.11-52.945-38.787C98.682 28.01 83.385 14.42 62.894 9.43a57.387 57.387 0 00-39.425 3.988A77.699 77.699 0 000 31.928z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E");
    }

    .btn {
        background-color: $orange;
        border-color: $orange;

        &:hover {
            background-color: $orange2;
            border-color: $orange2;
        }
    }
}

.afisha {
    .item {

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

    }

    h3 {

        a {
            color: $text;

            &:hover {

                color: $yellow;
            }
        }
    }

    p {
        margin-bottom: 0;
        display: inline;
        font-weight: lighter;
    }
}

.partners-block {
    background: linear-gradient(123.56deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%);

    .img-wrap {
        min-height: 120px;
    }

    p {
        margin: 35px 0 0;
        padding: 0 10px;
    }

    .slick-slide {
        margin: 0 10px;
    }
}

.contact-block {
    form {
        max-width: 540px;

        input,
        textarea {
            border: 1px solid rgba($white, 0.7);
            color: $white;
        }

        ::placeholder {
            color: $white;
            opacity: .7;
        }

        :-ms-input-placeholder {
            color: $white;
            opacity: .7;
        }

        ::-ms-input-placeholder {
            color: $white;
            opacity: .7;
        }

        :focus::placeholder {
            color: $white;
            opacity: 1;
        }

        :focus::-ms-input-placeholder {
            color: $white;
            opacity: 1;
        }

        :focus::-ms-input-placeholder {
            color: $white;
            opacity: 1;
        }
    }
}

@media(max-width: 1780px) {

    .title-icon:before {
        display: none;
    }
}

@media(max-width: 1300px) {
    .clubs-block:before {
        display: none;
    }
}

@media(max-width: 1200px) {
    .first-screen {
        padding: 25vh 0 20vh;
        min-height: 70vh;
    }

    .about-block {
        .img {
            min-height: 500px;
        }
    }

    .theater {
        .text {
            background-size: 100%;
        }
    }

    .info-block {
        .img-wrap {
            display: inline-block;
        }

        .text-wrap {
            background-size: 100%;
        }

        .text {
            opacity: 1;
            background: rgba(172, 138, 90, .5);
        }
    }
}

@media(max-width: 992px) {
    .blog-block {
        .item {
            justify-content: space-between;
        }

        img {
            width: 100%;
        }
    }

    .theater {
        .text {
            background: none;
        }
    }

    .info-block {
        .text-wrap {
            background: none;
        }
    }

    .afisha {
        img {
            width: 100%;
        }
    }
}

@media(max-width: 767px) {
    h2 {
        font-size: 40px;
    }

    .home-page {
        .first-screen {
            padding: 20vh 0 15vh;

            svg {
                width: 100%;
            }
        }
    }

    .about-block {
        &:before {
            width: 90%;
            height: 230px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            background-size: 90% 230px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .video-wrap {
        min-height: 460px;
    }

    .blog-block {
        .item {
            flex-direction: column;

            .img-wrap {
                margin-bottom: 20px;
                order: 1;
                text-align: center;
                width: auto;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 284px;
                }
            }

            .text {
                order: 2;
            }
        }
    }

    .slick-prev {
        left: -5px;
    }

    .slick-next {
        right: -5px;
    }
}
