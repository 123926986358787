footer {
    background-color: $footer-bg;
    .container{
        max-width: 960px;
    }

    h6 {
        letter-spacing: 0.2em;
    }

    li {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            font-size: 14px;
        }
    }

    a {
        color: $white;

        &:hover {
            color: $yellow;
        }
    }
}
