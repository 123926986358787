header {
    position: absolute;
    z-index: 10;

    .info {
        background: rgba(60, 28, 0, 0.4);
    }

    .menu {
        padding: 6px 0;
        z-index: 9999;
        transition: .25s ease-in-out;

        &.active {
            & > div {
                transition: 0s;
                position: relative;
                right: 8.5px;
            }
        }

        &.menu-down {
            padding: 8px 0;
            transform: translate3d(0, 0, 0);
            position: fixed;
            top: 0;
            background: rgba(60, 28, 0, 0.6);

            .logo {
                svg {
                    width: 85px;
                    height: 70px;
                }
            }
        }

        &.menu-up {
            transform: translate3d(0, -100%, 0);
        }
    }

    a {
        color: $white;

        &:hover {
            color: $yellow;
        }
    }

    .social {

        svg {
            &:hover {
                path {
                    fill: $yellow;
                }
            }
        }
    }

    li {
        a.current {
            color: $yellow;
        }
    }
}

.lang {
    color: $white;
    cursor: pointer;

    .wrap {
        &:hover {
            span {
                color: $yellow;
            }

            path {
                stroke: $yellow;
            }
        }

        &.show {
            span {
                color: $yellow;
            }

            path {
                stroke: $yellow;
            }
        }
    }

    span {
        transition: .25s ease-in-out;
        min-width: 30px;
    }

    svg {
        margin-top: -8px;
    }

    ul {
        left: -8px;
        top: 25px;
        background: $yellow;
        display: none;
        width: calc(100% + 16px);

        &.show {
            display: block;
        }
    }

    li {
        margin-bottom: 4px;

        a {
            &:hover {
                color: $white;
                text-shadow: 0 0 0.7px $white, 0 0 0.7px $white;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.fix-menu {
    background: rgba(60, 28, 0, 0.6);
}

.blog-page,
.blog-page-inside,
.privacy-page,
.editor-page {
    header {
        position: relative;
        height: 150px;

        .info {
            background: rgba(60, 28, 0, 0.1);
        }

        .menu {
            background: rgba(60, 28, 0, 0.03);
        }

        a {
            color: $text;

            &:hover {
                color: $yellow;
            }
        }

        .lang {
            a {
                color: $white;
            }
        }

        .social {
            path {
                fill: $text;
            }
        }

        .logo .text {
            fill: $text;
        }

        .menu-down {
            background: rgba(#f6f6f6, 0.95);
        }
    }

    .lang {
        color: $text;

        path {
            stroke: $text;
        }
    }

    .fix-menu {
        background: rgba(#f6f6f6, 0.95);

        .text {
            fill: $orange;
        }
    }


}


.nav-icon-5 {
    width: 25px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    span {
        background-color: $yellow;
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 3px;
        transition-duration: 500ms;

        &:nth-child(1) {
            top: 0px;
            left: 0px;
        }

        &:nth-child(2) {
            top: 8px;
            left: 0px;
            opacity: 1;
        }

        &:nth-child(3) {
            bottom: 1px;
            left: 0px;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: 13px;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 13px;
            }
        }
    }
}

.mm-navbar {
    display: none;
}

.mm-menu {
    padding-top: 50px;
    padding-left: 50px;
}

.mm-listitem__text {
    white-space: normal;
}

.mm-listitem {

    a {
        color: $black;

        &.current {
            color: $yellow;
        }
    }

    &:after {
        display: none;
    }
}

.mm-sticky.mh-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    transition: transform .2s ease;
    transform: translate3d(0, 0, 0);
}

.mm-sticky.mh-head.mh-scrolledout {
    transform: translate3d(0, -100%, 0);
}

#menu {
    opacity: 0;

    &.mm-menu_opened {
        opacity: 1;
    }

    .btn {
        margin-left: 20px;
        max-width: 220px;
    }
}

.mm-listitem {

    &.social,
    &.lang {
        a {
            max-width: 60px;

            &.current {
                color: $yellow;
            }
        }
    }

}
