@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #69380D;
  --yellow: #C8AF43;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #7A7A7A;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #7A7A7A;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #C8AF43;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --text: #212121;
  --orange: #69380D;
  --yellow2: #AE941E;
  --orange2: #AC8A5A;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left; }

@media (max-width: 1500px) {
  body {
    font-size: 0.9rem; } }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #7A7A7A;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.8rem; }

h2, .h2 {
  font-size: 3.5rem; }

h3, .h3 {
  font-size: 1.562rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

@media (max-width: 1500px) {
  h1, .h1 {
    font-size: 2.52rem; }
  h2, .h2 {
    font-size: 3.15rem; }
  h3, .h3 {
    font-size: 1.4058rem; }
  h4, .h4 {
    font-size: 1.125rem; }
  h5, .h5 {
    font-size: 1.0125rem; }
  h6, .h6 {
    font-size: 0.9rem; } }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #7A7A7A; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #7A7A7A; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #7A7A7A;
  border-color: #7A7A7A; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #676767;
    border-color: #616161; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #676767;
    border-color: #616161;
    box-shadow: 0 0 0 0.2rem rgba(142, 142, 142, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #7A7A7A;
    border-color: #7A7A7A; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #616161;
    border-color: #5a5a5a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(142, 142, 142, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #C8AF43;
  border-color: #C8AF43; }
  .btn-warning:hover {
    color: #fff;
    background-color: #b19934;
    border-color: #a79131; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #b19934;
    border-color: #a79131;
    box-shadow: 0 0 0 0.2rem rgba(175, 154, 63, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #C8AF43;
    border-color: #C8AF43; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #a79131;
    border-color: #9d882e; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(175, 154, 63, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-text {
  color: #fff;
  background-color: #212121;
  border-color: #212121; }
  .btn-text:hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #080808; }
  .btn-text:focus, .btn-text.focus {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #080808;
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-text.disabled, .btn-text:disabled {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
  .btn-text:not(:disabled):not(.disabled):active, .btn-text:not(:disabled):not(.disabled).active,
  .show > .btn-text.dropdown-toggle {
    color: #fff;
    background-color: #080808;
    border-color: #010101; }
    .btn-text:not(:disabled):not(.disabled):active:focus, .btn-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-orange {
  color: #fff;
  background-color: #69380D;
  border-color: #69380D; }
  .btn-orange:hover {
    color: #fff;
    background-color: #472609;
    border-color: #3c2007; }
  .btn-orange:focus, .btn-orange.focus {
    color: #fff;
    background-color: #472609;
    border-color: #3c2007;
    box-shadow: 0 0 0 0.2rem rgba(128, 86, 49, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #69380D;
    border-color: #69380D; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #3c2007;
    border-color: #301a06; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 86, 49, 0.5); }

.btn-yellow2 {
  color: #fff;
  background-color: #AE941E;
  border-color: #AE941E; }
  .btn-yellow2:hover {
    color: #fff;
    background-color: #8d7818;
    border-color: #836f17; }
  .btn-yellow2:focus, .btn-yellow2.focus {
    color: #fff;
    background-color: #8d7818;
    border-color: #836f17;
    box-shadow: 0 0 0 0.2rem rgba(186, 164, 64, 0.5); }
  .btn-yellow2.disabled, .btn-yellow2:disabled {
    color: #fff;
    background-color: #AE941E;
    border-color: #AE941E; }
  .btn-yellow2:not(:disabled):not(.disabled):active, .btn-yellow2:not(:disabled):not(.disabled).active,
  .show > .btn-yellow2.dropdown-toggle {
    color: #fff;
    background-color: #836f17;
    border-color: #786615; }
    .btn-yellow2:not(:disabled):not(.disabled):active:focus, .btn-yellow2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 164, 64, 0.5); }

.btn-orange2 {
  color: #fff;
  background-color: #AC8A5A;
  border-color: #AC8A5A; }
  .btn-orange2:hover {
    color: #fff;
    background-color: #95764b;
    border-color: #8c6f47; }
  .btn-orange2:focus, .btn-orange2.focus {
    color: #fff;
    background-color: #95764b;
    border-color: #8c6f47;
    box-shadow: 0 0 0 0.2rem rgba(184, 156, 115, 0.5); }
  .btn-orange2.disabled, .btn-orange2:disabled {
    color: #fff;
    background-color: #AC8A5A;
    border-color: #AC8A5A; }
  .btn-orange2:not(:disabled):not(.disabled):active, .btn-orange2:not(:disabled):not(.disabled).active,
  .show > .btn-orange2.dropdown-toggle {
    color: #fff;
    background-color: #8c6f47;
    border-color: #846942; }
    .btn-orange2:not(:disabled):not(.disabled):active:focus, .btn-orange2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(184, 156, 115, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #7A7A7A;
  border-color: #7A7A7A; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #7A7A7A;
    border-color: #7A7A7A; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 122, 122, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7A7A7A;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7A7A7A;
    border-color: #7A7A7A; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(122, 122, 122, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #C8AF43;
  border-color: #C8AF43; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #C8AF43;
    border-color: #C8AF43; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 175, 67, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #C8AF43;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #C8AF43;
    border-color: #C8AF43; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 175, 67, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-text {
  color: #212121;
  border-color: #212121; }
  .btn-outline-text:hover {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
  .btn-outline-text:focus, .btn-outline-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5); }
  .btn-outline-text.disabled, .btn-outline-text:disabled {
    color: #212121;
    background-color: transparent; }
  .btn-outline-text:not(:disabled):not(.disabled):active, .btn-outline-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text.dropdown-toggle {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }
    .btn-outline-text:not(:disabled):not(.disabled):active:focus, .btn-outline-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5); }

.btn-outline-orange {
  color: #69380D;
  border-color: #69380D; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #69380D;
    border-color: #69380D; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 56, 13, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #69380D;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #69380D;
    border-color: #69380D; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 56, 13, 0.5); }

.btn-outline-yellow2 {
  color: #AE941E;
  border-color: #AE941E; }
  .btn-outline-yellow2:hover {
    color: #fff;
    background-color: #AE941E;
    border-color: #AE941E; }
  .btn-outline-yellow2:focus, .btn-outline-yellow2.focus {
    box-shadow: 0 0 0 0.2rem rgba(174, 148, 30, 0.5); }
  .btn-outline-yellow2.disabled, .btn-outline-yellow2:disabled {
    color: #AE941E;
    background-color: transparent; }
  .btn-outline-yellow2:not(:disabled):not(.disabled):active, .btn-outline-yellow2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow2.dropdown-toggle {
    color: #fff;
    background-color: #AE941E;
    border-color: #AE941E; }
    .btn-outline-yellow2:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(174, 148, 30, 0.5); }

.btn-outline-orange2 {
  color: #AC8A5A;
  border-color: #AC8A5A; }
  .btn-outline-orange2:hover {
    color: #fff;
    background-color: #AC8A5A;
    border-color: #AC8A5A; }
  .btn-outline-orange2:focus, .btn-outline-orange2.focus {
    box-shadow: 0 0 0 0.2rem rgba(172, 138, 90, 0.5); }
  .btn-outline-orange2.disabled, .btn-outline-orange2:disabled {
    color: #AC8A5A;
    background-color: transparent; }
  .btn-outline-orange2:not(:disabled):not(.disabled):active, .btn-outline-orange2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange2.dropdown-toggle {
    color: #fff;
    background-color: #AC8A5A;
    border-color: #AC8A5A; }
    .btn-outline-orange2:not(:disabled):not(.disabled):active:focus, .btn-outline-orange2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(172, 138, 90, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #7A7A7A;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #7A7A7A;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #7A7A7A;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #7A7A7A;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e5e5e5; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #e5e5e5; }
    .nav-tabs .nav-link.disabled {
      color: #7A7A7A;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #e5e5e5 #e5e5e5 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 3px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px; }

.page-link {
  position: relative;
  display: block;
  margin-left: -1px; }
  .page-link:hover {
    z-index: 2; }

.page-item.active .page-link {
  z-index: 3; }

.page-item.disabled .page-link {
  color: #7A7A7A;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e5e5e5; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
  display: none;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1051; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #7A7A7A;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e5e5e5;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #7A7A7A !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #616161 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #C8AF43 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #a79131 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-text {
  background-color: #212121 !important; }

a.bg-text:hover, a.bg-text:focus,
button.bg-text:hover,
button.bg-text:focus {
  background-color: #080808 !important; }

.bg-orange {
  background-color: #69380D !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #3c2007 !important; }

.bg-yellow2 {
  background-color: #AE941E !important; }

a.bg-yellow2:hover, a.bg-yellow2:focus,
button.bg-yellow2:hover,
button.bg-yellow2:focus {
  background-color: #836f17 !important; }

.bg-orange2 {
  background-color: #AC8A5A !important; }

a.bg-orange2:hover, a.bg-orange2:focus,
button.bg-orange2:hover,
button.bg-orange2:focus {
  background-color: #8c6f47 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e5e5e5 !important; }

.border-top {
  border-top: 1px solid #e5e5e5 !important; }

.border-right {
  border-right: 1px solid #e5e5e5 !important; }

.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important; }

.border-left {
  border-left: 1px solid #e5e5e5 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #7A7A7A !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #C8AF43 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-text {
  border-color: #212121 !important; }

.border-orange {
  border-color: #69380D !important; }

.border-yellow2 {
  border-color: #AE941E !important; }

.border-orange2 {
  border-color: #AC8A5A !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 3px !important; }

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important; }

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-35 {
  margin: 2.25rem !important; }

.mt-35,
.my-35 {
  margin-top: 2.25rem !important; }

.mr-35,
.mx-35 {
  margin-right: 2.25rem !important; }

.mb-35,
.my-35 {
  margin-bottom: 2.25rem !important; }

.ml-35,
.mx-35 {
  margin-left: 2.25rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 3.75rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.75rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.75rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.75rem !important; }

.m-7 {
  margin: 4.35rem !important; }

.mt-7,
.my-7 {
  margin-top: 4.35rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4.35rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4.35rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4.35rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 5.625rem !important; }

.mt-9,
.my-9 {
  margin-top: 5.625rem !important; }

.mr-9,
.mx-9 {
  margin-right: 5.625rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 5.625rem !important; }

.ml-9,
.mx-9 {
  margin-left: 5.625rem !important; }

.m-10 {
  margin: 6.25rem !important; }

.mt-10,
.my-10 {
  margin-top: 6.25rem !important; }

.mr-10,
.mx-10 {
  margin-right: 6.25rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 6.25rem !important; }

.ml-10,
.mx-10 {
  margin-left: 6.25rem !important; }

.m-11 {
  margin: 6.875rem !important; }

.mt-11,
.my-11 {
  margin-top: 6.875rem !important; }

.mr-11,
.mx-11 {
  margin-right: 6.875rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 6.875rem !important; }

.ml-11,
.mx-11 {
  margin-left: 6.875rem !important; }

.m-12 {
  margin: 7.5rem !important; }

.mt-12,
.my-12 {
  margin-top: 7.5rem !important; }

.mr-12,
.mx-12 {
  margin-right: 7.5rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 7.5rem !important; }

.ml-12,
.mx-12 {
  margin-left: 7.5rem !important; }

.m-13 {
  margin: 8.125rem !important; }

.mt-13,
.my-13 {
  margin-top: 8.125rem !important; }

.mr-13,
.mx-13 {
  margin-right: 8.125rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 8.125rem !important; }

.ml-13,
.mx-13 {
  margin-left: 8.125rem !important; }

.m-14 {
  margin: 8.75rem !important; }

.mt-14,
.my-14 {
  margin-top: 8.75rem !important; }

.mr-14,
.mx-14 {
  margin-right: 8.75rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 8.75rem !important; }

.ml-14,
.mx-14 {
  margin-left: 8.75rem !important; }

.m-15 {
  margin: 9.375rem !important; }

.mt-15,
.my-15 {
  margin-top: 9.375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 9.375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 9.375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 9.375rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-35 {
  padding: 2.25rem !important; }

.pt-35,
.py-35 {
  padding-top: 2.25rem !important; }

.pr-35,
.px-35 {
  padding-right: 2.25rem !important; }

.pb-35,
.py-35 {
  padding-bottom: 2.25rem !important; }

.pl-35,
.px-35 {
  padding-left: 2.25rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 3.75rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.75rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.75rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.75rem !important; }

.p-7 {
  padding: 4.35rem !important; }

.pt-7,
.py-7 {
  padding-top: 4.35rem !important; }

.pr-7,
.px-7 {
  padding-right: 4.35rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4.35rem !important; }

.pl-7,
.px-7 {
  padding-left: 4.35rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 5.625rem !important; }

.pt-9,
.py-9 {
  padding-top: 5.625rem !important; }

.pr-9,
.px-9 {
  padding-right: 5.625rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 5.625rem !important; }

.pl-9,
.px-9 {
  padding-left: 5.625rem !important; }

.p-10 {
  padding: 6.25rem !important; }

.pt-10,
.py-10 {
  padding-top: 6.25rem !important; }

.pr-10,
.px-10 {
  padding-right: 6.25rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 6.25rem !important; }

.pl-10,
.px-10 {
  padding-left: 6.25rem !important; }

.p-11 {
  padding: 6.875rem !important; }

.pt-11,
.py-11 {
  padding-top: 6.875rem !important; }

.pr-11,
.px-11 {
  padding-right: 6.875rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 6.875rem !important; }

.pl-11,
.px-11 {
  padding-left: 6.875rem !important; }

.p-12 {
  padding: 7.5rem !important; }

.pt-12,
.py-12 {
  padding-top: 7.5rem !important; }

.pr-12,
.px-12 {
  padding-right: 7.5rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 7.5rem !important; }

.pl-12,
.px-12 {
  padding-left: 7.5rem !important; }

.p-13 {
  padding: 8.125rem !important; }

.pt-13,
.py-13 {
  padding-top: 8.125rem !important; }

.pr-13,
.px-13 {
  padding-right: 8.125rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 8.125rem !important; }

.pl-13,
.px-13 {
  padding-left: 8.125rem !important; }

.p-14 {
  padding: 8.75rem !important; }

.pt-14,
.py-14 {
  padding-top: 8.75rem !important; }

.pr-14,
.px-14 {
  padding-right: 8.75rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 8.75rem !important; }

.pl-14,
.px-14 {
  padding-left: 8.75rem !important; }

.p-15 {
  padding: 9.375rem !important; }

.pt-15,
.py-15 {
  padding-top: 9.375rem !important; }

.pr-15,
.px-15 {
  padding-right: 9.375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 9.375rem !important; }

.pl-15,
.px-15 {
  padding-left: 9.375rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n20 {
  margin: -1.25rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -1.25rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -1.25rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -1.25rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -1.25rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n35 {
  margin: -2.25rem !important; }

.mt-n35,
.my-n35 {
  margin-top: -2.25rem !important; }

.mr-n35,
.mx-n35 {
  margin-right: -2.25rem !important; }

.mb-n35,
.my-n35 {
  margin-bottom: -2.25rem !important; }

.ml-n35,
.mx-n35 {
  margin-left: -2.25rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -3.75rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3.75rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3.75rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3.75rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3.75rem !important; }

.m-n7 {
  margin: -4.35rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4.35rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4.35rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4.35rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4.35rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -5.625rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -5.625rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -5.625rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -5.625rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -5.625rem !important; }

.m-n10 {
  margin: -6.25rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -6.25rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -6.25rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -6.25rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -6.25rem !important; }

.m-n11 {
  margin: -6.875rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -6.875rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -6.875rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -6.875rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -6.875rem !important; }

.m-n12 {
  margin: -7.5rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -7.5rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -7.5rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -7.5rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -7.5rem !important; }

.m-n13 {
  margin: -8.125rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -8.125rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -8.125rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -8.125rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -8.125rem !important; }

.m-n14 {
  margin: -8.75rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -8.75rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -8.75rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -8.75rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -8.75rem !important; }

.m-n15 {
  margin: -9.375rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -9.375rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -9.375rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -9.375rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -9.375rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-35 {
    margin: 2.25rem !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 2.25rem !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 2.25rem !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 2.25rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 3.75rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.75rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.75rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.75rem !important; }
  .m-sm-7 {
    margin: 4.35rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4.35rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4.35rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4.35rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4.35rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 5.625rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 5.625rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 5.625rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 5.625rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 5.625rem !important; }
  .m-sm-10 {
    margin: 6.25rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6.25rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6.25rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6.25rem !important; }
  .m-sm-11 {
    margin: 6.875rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 6.875rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 6.875rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 6.875rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 6.875rem !important; }
  .m-sm-12 {
    margin: 7.5rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 7.5rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 7.5rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 7.5rem !important; }
  .m-sm-13 {
    margin: 8.125rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 8.125rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 8.125rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 8.125rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 8.125rem !important; }
  .m-sm-14 {
    margin: 8.75rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 8.75rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 8.75rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 8.75rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 8.75rem !important; }
  .m-sm-15 {
    margin: 9.375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 9.375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 9.375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 9.375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 9.375rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-35 {
    padding: 2.25rem !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 2.25rem !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 2.25rem !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 2.25rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 3.75rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.75rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.75rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.75rem !important; }
  .p-sm-7 {
    padding: 4.35rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4.35rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4.35rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4.35rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4.35rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 5.625rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 5.625rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 5.625rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 5.625rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 5.625rem !important; }
  .p-sm-10 {
    padding: 6.25rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6.25rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6.25rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6.25rem !important; }
  .p-sm-11 {
    padding: 6.875rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 6.875rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 6.875rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 6.875rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 6.875rem !important; }
  .p-sm-12 {
    padding: 7.5rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 7.5rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 7.5rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 7.5rem !important; }
  .p-sm-13 {
    padding: 8.125rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 8.125rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 8.125rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 8.125rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 8.125rem !important; }
  .p-sm-14 {
    padding: 8.75rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 8.75rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 8.75rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 8.75rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 8.75rem !important; }
  .p-sm-15 {
    padding: 9.375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 9.375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 9.375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 9.375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 9.375rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n20 {
    margin: -1.25rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -1.25rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -1.25rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -1.25rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n35 {
    margin: -2.25rem !important; }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -2.25rem !important; }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -2.25rem !important; }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -2.25rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -3.75rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3.75rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3.75rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3.75rem !important; }
  .m-sm-n7 {
    margin: -4.35rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4.35rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4.35rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4.35rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4.35rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -5.625rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -5.625rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -5.625rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -5.625rem !important; }
  .m-sm-n10 {
    margin: -6.25rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6.25rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6.25rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6.25rem !important; }
  .m-sm-n11 {
    margin: -6.875rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -6.875rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -6.875rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -6.875rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -6.875rem !important; }
  .m-sm-n12 {
    margin: -7.5rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -7.5rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -7.5rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -7.5rem !important; }
  .m-sm-n13 {
    margin: -8.125rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -8.125rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -8.125rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -8.125rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -8.125rem !important; }
  .m-sm-n14 {
    margin: -8.75rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -8.75rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -8.75rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -8.75rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -8.75rem !important; }
  .m-sm-n15 {
    margin: -9.375rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -9.375rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -9.375rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -9.375rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -9.375rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-35 {
    margin: 2.25rem !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 2.25rem !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 2.25rem !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 2.25rem !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 2.25rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 3.75rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.75rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.75rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.75rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.75rem !important; }
  .m-md-7 {
    margin: 4.35rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4.35rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4.35rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4.35rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4.35rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 5.625rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 5.625rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 5.625rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 5.625rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 5.625rem !important; }
  .m-md-10 {
    margin: 6.25rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6.25rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6.25rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6.25rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6.25rem !important; }
  .m-md-11 {
    margin: 6.875rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 6.875rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 6.875rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 6.875rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 6.875rem !important; }
  .m-md-12 {
    margin: 7.5rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 7.5rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 7.5rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 7.5rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 7.5rem !important; }
  .m-md-13 {
    margin: 8.125rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 8.125rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 8.125rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 8.125rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 8.125rem !important; }
  .m-md-14 {
    margin: 8.75rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 8.75rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 8.75rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 8.75rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 8.75rem !important; }
  .m-md-15 {
    margin: 9.375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 9.375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 9.375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 9.375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 9.375rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-35 {
    padding: 2.25rem !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 2.25rem !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 2.25rem !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 2.25rem !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 2.25rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 3.75rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.75rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.75rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.75rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.75rem !important; }
  .p-md-7 {
    padding: 4.35rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4.35rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4.35rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4.35rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4.35rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 5.625rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 5.625rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 5.625rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 5.625rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 5.625rem !important; }
  .p-md-10 {
    padding: 6.25rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6.25rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6.25rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6.25rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6.25rem !important; }
  .p-md-11 {
    padding: 6.875rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 6.875rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 6.875rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 6.875rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 6.875rem !important; }
  .p-md-12 {
    padding: 7.5rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 7.5rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 7.5rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 7.5rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 7.5rem !important; }
  .p-md-13 {
    padding: 8.125rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 8.125rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 8.125rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 8.125rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 8.125rem !important; }
  .p-md-14 {
    padding: 8.75rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 8.75rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 8.75rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 8.75rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 8.75rem !important; }
  .p-md-15 {
    padding: 9.375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 9.375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 9.375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 9.375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 9.375rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n20 {
    margin: -1.25rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -1.25rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -1.25rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -1.25rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n35 {
    margin: -2.25rem !important; }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -2.25rem !important; }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -2.25rem !important; }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -2.25rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -3.75rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3.75rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3.75rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3.75rem !important; }
  .m-md-n7 {
    margin: -4.35rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4.35rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4.35rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4.35rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4.35rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -5.625rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -5.625rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -5.625rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -5.625rem !important; }
  .m-md-n10 {
    margin: -6.25rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6.25rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6.25rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6.25rem !important; }
  .m-md-n11 {
    margin: -6.875rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -6.875rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -6.875rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -6.875rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -6.875rem !important; }
  .m-md-n12 {
    margin: -7.5rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -7.5rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -7.5rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -7.5rem !important; }
  .m-md-n13 {
    margin: -8.125rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -8.125rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -8.125rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -8.125rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -8.125rem !important; }
  .m-md-n14 {
    margin: -8.75rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -8.75rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -8.75rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -8.75rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -8.75rem !important; }
  .m-md-n15 {
    margin: -9.375rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -9.375rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -9.375rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -9.375rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -9.375rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-35 {
    margin: 2.25rem !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 2.25rem !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 2.25rem !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 2.25rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 3.75rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.75rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.75rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.75rem !important; }
  .m-lg-7 {
    margin: 4.35rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4.35rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4.35rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4.35rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4.35rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 5.625rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 5.625rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 5.625rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 5.625rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 5.625rem !important; }
  .m-lg-10 {
    margin: 6.25rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6.25rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6.25rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6.25rem !important; }
  .m-lg-11 {
    margin: 6.875rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 6.875rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 6.875rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 6.875rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 6.875rem !important; }
  .m-lg-12 {
    margin: 7.5rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 7.5rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 7.5rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 7.5rem !important; }
  .m-lg-13 {
    margin: 8.125rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 8.125rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 8.125rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 8.125rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 8.125rem !important; }
  .m-lg-14 {
    margin: 8.75rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 8.75rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 8.75rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 8.75rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 8.75rem !important; }
  .m-lg-15 {
    margin: 9.375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 9.375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 9.375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 9.375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 9.375rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-35 {
    padding: 2.25rem !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 2.25rem !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 2.25rem !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 2.25rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 3.75rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.75rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.75rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.75rem !important; }
  .p-lg-7 {
    padding: 4.35rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4.35rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4.35rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4.35rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4.35rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 5.625rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 5.625rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 5.625rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 5.625rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 5.625rem !important; }
  .p-lg-10 {
    padding: 6.25rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6.25rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6.25rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6.25rem !important; }
  .p-lg-11 {
    padding: 6.875rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 6.875rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 6.875rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 6.875rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 6.875rem !important; }
  .p-lg-12 {
    padding: 7.5rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 7.5rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 7.5rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 7.5rem !important; }
  .p-lg-13 {
    padding: 8.125rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 8.125rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 8.125rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 8.125rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 8.125rem !important; }
  .p-lg-14 {
    padding: 8.75rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 8.75rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 8.75rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 8.75rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 8.75rem !important; }
  .p-lg-15 {
    padding: 9.375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 9.375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 9.375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 9.375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 9.375rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n20 {
    margin: -1.25rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -1.25rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -1.25rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -1.25rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n35 {
    margin: -2.25rem !important; }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -2.25rem !important; }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -2.25rem !important; }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -2.25rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -3.75rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3.75rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3.75rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3.75rem !important; }
  .m-lg-n7 {
    margin: -4.35rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4.35rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4.35rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4.35rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4.35rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -5.625rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -5.625rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -5.625rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -5.625rem !important; }
  .m-lg-n10 {
    margin: -6.25rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6.25rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6.25rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6.25rem !important; }
  .m-lg-n11 {
    margin: -6.875rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -6.875rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -6.875rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -6.875rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -6.875rem !important; }
  .m-lg-n12 {
    margin: -7.5rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -7.5rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -7.5rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -7.5rem !important; }
  .m-lg-n13 {
    margin: -8.125rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -8.125rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -8.125rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -8.125rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -8.125rem !important; }
  .m-lg-n14 {
    margin: -8.75rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -8.75rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -8.75rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -8.75rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -8.75rem !important; }
  .m-lg-n15 {
    margin: -9.375rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -9.375rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -9.375rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -9.375rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -9.375rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-35 {
    margin: 2.25rem !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 2.25rem !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 2.25rem !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 2.25rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 3.75rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3.75rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3.75rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3.75rem !important; }
  .m-xl-7 {
    margin: 4.35rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4.35rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4.35rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4.35rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4.35rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 5.625rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 5.625rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 5.625rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 5.625rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 5.625rem !important; }
  .m-xl-10 {
    margin: 6.25rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6.25rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6.25rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6.25rem !important; }
  .m-xl-11 {
    margin: 6.875rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 6.875rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 6.875rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 6.875rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 6.875rem !important; }
  .m-xl-12 {
    margin: 7.5rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 7.5rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 7.5rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 7.5rem !important; }
  .m-xl-13 {
    margin: 8.125rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 8.125rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 8.125rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 8.125rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 8.125rem !important; }
  .m-xl-14 {
    margin: 8.75rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 8.75rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 8.75rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 8.75rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 8.75rem !important; }
  .m-xl-15 {
    margin: 9.375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 9.375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 9.375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 9.375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 9.375rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-35 {
    padding: 2.25rem !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 2.25rem !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 2.25rem !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 2.25rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 3.75rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3.75rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3.75rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3.75rem !important; }
  .p-xl-7 {
    padding: 4.35rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4.35rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4.35rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4.35rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4.35rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 5.625rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 5.625rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 5.625rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 5.625rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 5.625rem !important; }
  .p-xl-10 {
    padding: 6.25rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6.25rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6.25rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6.25rem !important; }
  .p-xl-11 {
    padding: 6.875rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 6.875rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 6.875rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 6.875rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 6.875rem !important; }
  .p-xl-12 {
    padding: 7.5rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 7.5rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 7.5rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 7.5rem !important; }
  .p-xl-13 {
    padding: 8.125rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 8.125rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 8.125rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 8.125rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 8.125rem !important; }
  .p-xl-14 {
    padding: 8.75rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 8.75rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 8.75rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 8.75rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 8.75rem !important; }
  .p-xl-15 {
    padding: 9.375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 9.375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 9.375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 9.375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 9.375rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n20 {
    margin: -1.25rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -1.25rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -1.25rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -1.25rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n35 {
    margin: -2.25rem !important; }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -2.25rem !important; }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -2.25rem !important; }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -2.25rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -3.75rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3.75rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3.75rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3.75rem !important; }
  .m-xl-n7 {
    margin: -4.35rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4.35rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4.35rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4.35rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4.35rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -5.625rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -5.625rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -5.625rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -5.625rem !important; }
  .m-xl-n10 {
    margin: -6.25rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6.25rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6.25rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6.25rem !important; }
  .m-xl-n11 {
    margin: -6.875rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -6.875rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -6.875rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -6.875rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -6.875rem !important; }
  .m-xl-n12 {
    margin: -7.5rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -7.5rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -7.5rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -7.5rem !important; }
  .m-xl-n13 {
    margin: -8.125rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -8.125rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -8.125rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -8.125rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -8.125rem !important; }
  .m-xl-n14 {
    margin: -8.75rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -8.75rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -8.75rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -8.75rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -8.75rem !important; }
  .m-xl-n15 {
    margin: -9.375rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -9.375rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -9.375rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -9.375rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -9.375rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #7A7A7A !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545454 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #C8AF43 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #93802b !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-text {
  color: #212121 !important; }

a.text-text:hover, a.text-text:focus {
  color: black !important; }

.text-orange {
  color: #69380D !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #251405 !important; }

.text-yellow2 {
  color: #AE941E !important; }

a.text-yellow2:hover, a.text-yellow2:focus {
  color: #6d5d13 !important; }

.text-orange2 {
  color: #AC8A5A !important; }

a.text-orange2:hover, a.text-orange2:focus {
  color: #7b623e !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #7A7A7A !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

:root {
  --mm-line-height: 20px;
  --mm-listitem-size: 44px;
  --mm-navbar-size: 44px;
  --mm-offset-top: 0;
  --mm-offset-right: 0;
  --mm-offset-bottom: 0;
  --mm-offset-left: 0;
  --mm-color-border: rgba(0, 0, 0, 0.1);
  --mm-color-button: rgba(0, 0, 0, 0.3);
  --mm-color-text: rgba(0, 0, 0, 0.75);
  --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
  --mm-color-background: #f3f3f3;
  --mm-color-background-highlight: rgba(0, 0, 0, 0.05);
  --mm-color-background-emphasis: rgba(255, 255, 255, 0.4);
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
; }

.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  background: #fff;
  border-color: var(--mm-color-border);
  color: var(--mm-color-text);
  line-height: var(--mm-line-height);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: absolute;
  top: var(--mm-offset-top);
  right: var(--mm-offset-right);
  bottom: var(--mm-offset-bottom);
  left: var(--mm-offset-left);
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  text-decoration: none; }

[dir=rtl] .mm-menu {
  direction: rtl; }

.mm-panel {
  background: #fff;
  border-color: var(--mm-color-border);
  color: var(--mm-color-text);
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease; }

.mm-panel:not(.mm-hidden) {
  display: block; }

.mm-panel:after {
  content: '';
  display: block;
  height: 20px; }

.mm-panel_opened {
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-panel_opened-parent {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0); }

.mm-panel_highest {
  z-index: 2; }

.mm-panel_noanimation {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

.mm-panel_noanimation.mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-panel__content {
  padding: 20px; }

.mm-panels {
  background: #fff;
  border-color: var(--mm-color-border);
  color: var(--mm-color-text);
  position: relative;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden; }

.mm-panels > .mm-panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

[dir=rtl] .mm-panel:not(.mm-panel_opened) {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

[dir=rtl] .mm-panel.mm-panel_opened-parent {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0); }

.mm-listitem_vertical > .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  display: none;
  width: 100%;
  padding: 10px 0 10px 10px; }

.mm-listitem_vertical > .mm-panel:after,
.mm-listitem_vertical > .mm-panel:before {
  content: none;
  display: none; }

.mm-listitem_opened > .mm-panel {
  display: block; }

.mm-listitem_vertical > .mm-listitem__btn {
  height: 44px;
  height: var(--mm-listitem-size);
  bottom: auto; }

.mm-listitem_vertical .mm-listitem:last-child:after {
  border-color: transparent; }

.mm-listitem_opened > .mm-listitem__btn:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px; }

.mm-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 44px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0; }

.mm-btn:after,
.mm-btn:before {
  border-color: var(--mm-color-button);
  border-width: 2px;
  border-style: solid; }

.mm-btn_next:after,
.mm-btn_prev:before {
  content: '';
  border-bottom: none;
  border-right: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0; }

.mm-btn_prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto; }

.mm-btn_next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto; }

.mm-btn_close:after,
.mm-btn_close:before {
  content: '';
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.mm-btn_close:before {
  border-right: none;
  border-bottom: none;
  right: 18px; }

.mm-btn_close:after {
  border-left: none;
  border-top: none;
  right: 25px; }

[dir=rtl] .mm-btn_next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto; }

[dir=rtl] .mm-btn_prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto; }

[dir=rtl] .mm-btn_close:after,
[dir=rtl] .mm-btn_close:before {
  right: auto; }

[dir=rtl] .mm-btn_close:before {
  left: 25px; }

[dir=rtl] .mm-btn_close:after {
  left: 18px; }

.mm-navbar {
  background: var(--mm-color-background);
  border-color: var(--mm-color-border);
  color: var(--mm-color-text-dimmed);
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--mm-navbar-size);
  opacity: 1;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease; }

.mm-navbar > * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding: calc((var(--mm-navbar-size) - var(--mm-line-height)) * .5);
  padding-left: 0;
  padding-right: 0; }

.mm-navbar a,
.mm-navbar a:hover {
  text-decoration: none; }

.mm-navbar__title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%; }

.mm-navbar__btn {
  position: relative; }

.mm-navbar__btn:first-child {
  text-align: left; }

.mm-navbar__btn:last-child {
  text-align: right; }

[dir=rtl] .mm-navbar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

[dir=rtl] .mm-navbar__btn:first-child {
  text-align: right; }

[dir=rtl] .mm-navbar__btn:last-child {
  text-align: left; }

.mm-listview {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-listitem {
  color: var(--mm-color-text);
  border-color: var(--mm-color-border);
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.mm-listitem:after {
  content: '';
  border-color: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 20px;
  right: 0;
  bottom: 0; }

.mm-listitem a,
.mm-listitem a:hover {
  text-decoration: none; }

.mm-listitem__btn,
.mm-listitem__text {
  color: inherit;
  display: block;
  padding: calc((var(--mm-listitem-size) - var(--mm-line-height))/ 2);
  padding-left: 0;
  padding-right: 0; }

.mm-listitem__text {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%; }

.mm-listitem__btn {
  -webkit-tap-highlight-color: var(--mm-color-background-emphasis);
  tap-highlight-color: var(--mm-color-background-emphasis);
  background: rgba(3, 2, 1, 0);
  border-color: inherit;
  width: auto;
  padding-right: 54px;
  position: relative; }

.mm-listitem__btn:not(.mm-listitem__text) {
  border-left-width: 1px;
  border-left-style: solid; }

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
  background: var(--mm-color-background-highlight); }

[dir=rtl] .mm-listitem:after {
  left: 0;
  right: 20px; }

[dir=rtl] .mm-listitem__text {
  padding-left: 10px;
  padding-right: 20px; }

[dir=rtl] .mm-listitem__btn {
  padding-left: 54px;
  border-left-width: 0;
  border-left-style: none; }

[dir=rtl] .mm-listitem__btn:not(.mm-listitem__text) {
  padding-right: 0;
  border-right-width: 1px;
  border-right-style: solid; }

.mm-page {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  z-index: 1; }

.mm-wrapper_opened {
  overflow-x: hidden;
  position: relative; }

.mm-wrapper_background .mm-page {
  background: inherit; }

.mm-menu_offcanvas {
  position: fixed;
  right: auto;
  z-index: 0; }

.mm-menu_offcanvas:not(.mm-menu_opened) {
  display: none; }

.mm-menu_offcanvas {
  width: 80%;
  min-width: 240px;
  max-width: 440px; }

.mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_offcanvas ~ .mm-slideout {
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0); } }

.mm-wrapper__blocker {
  background: rgba(3, 2, 1, 0);
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }

.mm-wrapper_blocking {
  overflow: hidden; }

.mm-wrapper_blocking body {
  overflow: hidden; }

.mm-wrapper_blocking .mm-wrapper__blocker {
  display: block; }

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  min-width: 1px !important;
  height: 1px !important;
  min-height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important; }

.mm-menu_autoheight {
  -webkit-transition: none .4s ease;
  -o-transition: none .4s ease;
  transition: none .4s ease;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform; }

.mm-menu_autoheight:not(.mm-menu_offcanvas) {
  position: relative; }

.mm-menu_autoheight.mm-menu_position-bottom,
.mm-menu_autoheight.mm-menu_position-top {
  max-height: 80%; }

.mm-menu_autoheight-measuring .mm-panel {
  display: block !important; }

.mm-menu_autoheight-measuring .mm-listitem_vertical:not(.mm-listitem_opened) .mm-panel {
  display: none !important; }

.mm-menu_autoheight-measuring .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important; }

[class*=mm-menu_columns-] {
  -webkit-transition-property: width;
  -o-transition-property: width;
  transition-property: width; }

[class*=mm-menu_columns-] .mm-panels > .mm-panel {
  right: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, transform;
  transition-property: width, transform;
  transition-property: width, transform, -webkit-transform; }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_opened,
[class*=mm-menu_columns-] .mm-panels > .mm-panel_opened-parent {
  display: block !important; }

[class*=mm-panel_columns-] {
  border-right: 1px solid;
  border-color: inherit; }

.mm-menu_columns-1 .mm-panel_columns-0,
.mm-menu_columns-2 .mm-panel_columns-1,
.mm-menu_columns-3 .mm-panel_columns-2,
.mm-menu_columns-4 .mm-panel_columns-3 {
  border-right: none; }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-0 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_columns-0 .mm-panels > .mm-panel {
  z-index: 0; }

.mm-menu_columns-0 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-menu_columns-0 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-menu_columns-0 {
  width: 80%;
  min-width: 240px;
  max-width: 0; }

.mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 0px) {
  .mm-wrapper_opening .mm-menu_columns-0 ~ .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 0px) {
  .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-1 {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-menu_columns-1 .mm-panels > .mm-panel {
  z-index: 1;
  width: 100%; }

.mm-menu_columns-1 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-menu_columns-1 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0); }

.mm-menu_columns-1 {
  width: 80%;
  min-width: 240px;
  max-width: 440px; }

.mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_columns-1 ~ .mm-slideout {
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0); } }

.mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0); } }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-2 {
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0); }

.mm-menu_columns-2 .mm-panels > .mm-panel {
  z-index: 2;
  width: 50%; }

.mm-menu_columns-2 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-menu_columns-2 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0); }

.mm-menu_columns-2 {
  width: 80%;
  min-width: 240px;
  max-width: 880px; }

.mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 1100px) {
  .mm-wrapper_opening .mm-menu_columns-2 ~ .mm-slideout {
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0); } }

.mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 1100px) {
  .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0); } }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-3 {
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0); }

.mm-menu_columns-3 .mm-panels > .mm-panel {
  z-index: 3;
  width: 33.34%; }

.mm-menu_columns-3 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-menu_columns-3 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0); }

.mm-menu_columns-3 {
  width: 80%;
  min-width: 240px;
  max-width: 1320px; }

.mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 1650px) {
  .mm-wrapper_opening .mm-menu_columns-3 ~ .mm-slideout {
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0); } }

.mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 1650px) {
  .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0); } }

[class*=mm-menu_columns-] .mm-panels > .mm-panel_columns-4 {
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0); }

.mm-menu_columns-4 .mm-panels > .mm-panel {
  z-index: 4;
  width: 25%; }

.mm-menu_columns-4 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-menu_columns-4 .mm-panels > .mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0); }

.mm-menu_columns-4 {
  width: 80%;
  min-width: 240px;
  max-width: 1760px; }

.mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 2200px) {
  .mm-wrapper_opening .mm-menu_columns-4 ~ .mm-slideout {
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0); } }

.mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 2200px) {
  .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0); } }

[class*=mm-menu_columns-].mm-menu_position-bottom,
[class*=mm-menu_columns-].mm-menu_position-top {
  width: 100%;
  max-width: 100%;
  min-width: 100%; }

.mm-wrapper_opening [class*=mm-menu_columns-].mm-menu_position-front {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: width, min-width, max-width, -webkit-transform;
  -o-transition-property: width, min-width, max-width, transform;
  transition-property: width, min-width, max-width, transform;
  transition-property: width, min-width, max-width, transform, -webkit-transform; }

.mm-counter {
  color: var(--mm-color-text-dimmed);
  text-align: right;
  display: block;
  padding-left: 20px;
  float: right; }

.mm-listitem_nosubitems > .mm-counter {
  display: none; }

[dir=rtl] .mm-counter {
  text-align: left;
  float: left;
  padding-left: 0;
  padding-right: 20px; }

.mm-divider {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease;
  font-size: 75%;
  text-transform: uppercase;
  background: var(--mm-color-background);
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  min-height: var(--mm-line-height);
  padding: calc(((var(--mm-listitem-size) * .65) - var(--mm-line-height)) * .5);
  padding-right: 10px;
  padding-left: 20px; }

.mm-divider:before {
  content: '';
  background: var(--mm-color-background-highlight);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1; }

.mm-menu_dividers-light .mm-divider {
  font-size: inherit;
  color: var(--mm-color-text-dimmed);
  padding-top: calc((var(--mm-listitem-size) - var(--mm-line-height)) * .75);
  padding-bottom: calc((var(--mm-listitem-size) - var(--mm-line-height)) * .25); }

.mm-menu_dividers-light .mm-divider:before {
  background: inherit; }

.mm-menu_border-none .mm-divider {
  border-top-width: 1px;
  border-top-style: solid; }

.mm-wrapper_dragging .mm-menu,
.mm-wrapper_dragging .mm-slideout {
  -webkit-transition-duration: 0s !important;
  -o-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.mm-menu_dropdown {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%; }

.mm-wrapper_dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

.mm-wrapper_dropdown .mm-wrapper__blocker {
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1; }

.mm-wrapper_dropdown .mm-menu_dropdown {
  z-index: 2; }

.mm-wrapper_dropdown.mm-wrapper_opened:not(.mm-wrapper_opening) .mm-menu_dropdown {
  display: none; }

[class*=mm-menu_tip-]:before {
  content: '';
  background: inherit;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-menu_tip-left:before {
  left: 22px; }

.mm-menu_tip-right:before {
  right: 22px; }

.mm-menu_tip-top:before {
  top: -8px; }

.mm-menu_tip-bottom:before {
  bottom: -8px; }

:root {
  --mm-iconbar-size: 44px
; }

.mm-menu_iconbar-left .mm-navbars_bottom,
.mm-menu_iconbar-left .mm-navbars_top,
.mm-menu_iconbar-left .mm-panels {
  margin-left: var(--mm-iconbar-size); }

.mm-menu_iconbar-left .mm-iconbar {
  border-right-width: 1px;
  display: block;
  left: 0; }

.mm-menu_iconbar-right .mm-navbars_bottom,
.mm-menu_iconbar-right .mm-navbars_top,
.mm-menu_iconbar-right .mm-panels {
  margin-right: var(--mm-iconbar-size); }

.mm-menu_iconbar-right .mm-iconbar {
  border-left-width: 1px;
  display: block;
  right: 0; }

.mm-iconbar {
  background: var(--mm-color-background);
  color: var(--mm-color-text-dimmed);
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-color: var(--mm-color-border);
  text-align: center;
  overflow: hidden;
  display: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: var(--mm-iconbar-size);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2; }

.mm-iconbar__bottom,
.mm-iconbar__top {
  width: inherit;
  position: absolute; }

.mm-iconbar__bottom > *,
.mm-iconbar__top > * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding: 11px 0; }

.mm-iconbar__bottom a,
.mm-iconbar__bottom a:hover,
.mm-iconbar__top a,
.mm-iconbar__top a:hover {
  text-decoration: none; }

.mm-iconbar__top {
  top: 0; }

.mm-iconbar__bottom {
  bottom: 0; }

.mm-iconbar__tab_selected {
  background: var(--mm-color-background-emphasis); }

:root {
  --mm-iconpanel-size: 44px
; }

.mm-panel_iconpanel-1 {
  width: calc(100% - (var(--mm-iconpanel-size) * 1)); }

.mm-panel_iconpanel-2 {
  width: calc(100% - (var(--mm-iconpanel-size) * 2)); }

.mm-panel_iconpanel-3 {
  width: calc(100% - (var(--mm-iconpanel-size) * 3)); }

.mm-panel_iconpanel-first ~ .mm-panel {
  width: calc(100% - var(--mm-iconpanel-size)); }

.mm-menu_iconpanel .mm-panels > .mm-panel {
  left: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: transform, width;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform; }

.mm-menu_iconpanel .mm-panels > .mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
  border-left-width: 1px;
  border-left-style: solid; }

.mm-menu_iconpanel .mm-panels > .mm-panel_opened,
.mm-menu_iconpanel .mm-panels > .mm-panel_opened-parent {
  display: block !important; }

.mm-menu_iconpanel .mm-panels > .mm-panel_opened-parent {
  overflow-y: hidden;
  -webkit-transform: unset;
  -ms-transform: unset;
  transform: unset; }

.mm-menu_hidedivider .mm-panel_opened-parent .mm-divider,
.mm-menu_hidenavbar .mm-panel_opened-parent .mm-navbar {
  opacity: 0; }

.mm-panel__blocker {
  background: inherit;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease; }

.mm-panel_opened-parent .mm-panel__blocker {
  opacity: .6;
  bottom: -100000px; }

[dir=rtl] .mm-menu_iconpanel .mm-panels > .mm-panel {
  left: 0;
  right: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: transform, width;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform; }

[dir=rtl] .mm-menu_iconpanel .mm-panels > .mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit; }

.mm-menu_keyboardfocus a:focus,
.mm-menu_keyboardfocus.mm-menu_opened ~ .mm-wrapper__blocker a:focus {
  outline: 0;
  background: var(--mm-color-background-emphasis); }

.mm-wrapper__blocker .mm-tabstart {
  cursor: default;
  display: block;
  width: 100%;
  height: 100%; }

.mm-wrapper__blocker .mm-tabend {
  opacity: 0;
  position: absolute;
  bottom: 0; }

.mm-navbars_bottom,
.mm-navbars_top {
  background: inherit;
  border-color: inherit;
  border-width: 0;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.mm-navbars_bottom > .mm-navbar,
.mm-navbars_top > .mm-navbar {
  border-width: 0; }

.mm-navbars_bottom > .mm-navbar > :not(img):not(.mm-btn),
.mm-navbars_top > .mm-navbar > :not(img):not(.mm-btn) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.mm-navbars_top {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.mm-navbars_bottom {
  border-top-style: solid;
  border-top-width: 1px; }

.mm-navbar__breadcrumbs {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  padding: 0; }

.mm-navbar__breadcrumbs > * {
  display: inline-block;
  padding: calc((var(--mm-navbar-size) - var(--mm-line-height)) * .5);
  padding-left: 0;
  padding-right: 6px; }

.mm-navbar__breadcrumbs > a {
  text-decoration: underline; }

.mm-btn.mm-hidden + .mm-navbar__breadcrumbs {
  padding-left: 20px; }

.mm-navbar__tab_selected {
  background: inherit !important;
  color: inherit !important;
  border-width: 1px;
  border-style: solid;
  border-color: inherit; }

.mm-navbar__tab_selected:first-child {
  border-left: none; }

.mm-navbar__tab_selected:last-child {
  border-right: none; }

.mm-navbars_top.mm-navbars_has-tabs .mm-navbar_tabs,
.mm-navbars_top.mm-navbars_has-tabs .mm-navbar_tabs ~ .mm-navbar {
  background: inherit !important;
  color: inherit !important; }

.mm-navbars_top .mm-navbar_tabs:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mm-navbars_top .mm-navbar__tab_selected {
  border-bottom: none;
  margin-bottom: -1px; }

.mm-navbars_top .mm-navbar_tabs:first-child .mm-navbar__tab_selected {
  border-top: none; }

.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar {
  background: inherit;
  color: inherit; }

.mm-navbars_bottom .mm-navbar_tabs:not(:first-child) {
  border-top-width: 1px;
  border-top-style: solid; }

.mm-navbars_bottom .mm-navbar__tab_selected {
  border-top: none;
  margin-top: -1px; }

.mm-navbars_bottom .mm-navbar_tabs:last-child .mm-navbar__tab_selected {
  border-bottom: none; }

.mm-navbar_tabs > a:not(.mm-navbar__tab_selected),
.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar_tabs ~ .mm-navbar,
.mm-navbars_top.mm-navbars_has-tabs > .mm-navbar:not(.mm-navbar_tabs) {
  background: var(--mm-color-background-emphasis);
  color: var(--mm-color-text-dimmed); }

.mm-searchfield {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: var(--mm-navbar-size);
  padding: 0;
  overflow: hidden; }

.mm-searchfield input {
  border: none !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: inherit;
  line-height: calc(var(--mm-navbar-size) * .7);
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: calc(var(--mm-navbar-size) * .7);
  min-height: unset;
  max-height: unset;
  margin: 0;
  padding: 0 10px; }

.mm-searchfield input,
.mm-searchfield input:focus,
.mm-searchfield input:hover {
  background: var(--mm-color-background-highlight);
  color: var(--mm-color-text); }

.mm-searchfield input::-ms-clear {
  display: none; }

.mm-searchfield__input {
  padding: 6.6px 10px 0 10px;
  padding-top: calc(var(--mm-navbar-size) * .15);
  position: relative; }

.mm-panel__noresultsmsg {
  color: var(--mm-color-text-dimmed);
  text-align: center;
  font-size: 150%;
  padding: 44px 0; }

.mm-searchfield__btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0; }

.mm-panel_search {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  border-left: none !important; }

.mm-searchfield__input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%; }

.mm-searchfield__cancel {
  line-height: var(--mm-navbar-size);
  text-decoration: none;
  display: block;
  padding-right: 10px;
  margin-right: -100px;
  -webkit-transition: margin .4s ease;
  -o-transition: margin .4s ease;
  transition: margin .4s ease; }

.mm-searchfield__cancel-active {
  margin-right: 0; }

.mm-listitem_nosubitems > .mm-listitem__btn {
  display: none; }

.mm-listitem_nosubitems > .mm-listitem__text {
  padding-right: 10px; }

.mm-sectionindexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  z-index: 5;
  -webkit-transition: right .4s ease;
  -o-transition: right .4s ease;
  transition: right .4s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.mm-sectionindexer a {
  color: var(--mm-color-text-dimmed);
  line-height: 1;
  text-decoration: none;
  display: block; }

.mm-sectionindexer ~ .mm-panel {
  padding-right: 0;
  -webkit-transition: padding-right .4s ease;
  -o-transition: padding-right .4s ease;
  transition: padding-right .4s ease; }

.mm-sectionindexer_active {
  right: 0; }

.mm-sectionindexer_active ~ .mm-panel {
  padding-right: 20px; }

:root {
  --mm-sidebar-collapsed-size: 44px;
  --mm-sidebar-expanded-size: 440px
; }

.mm-wrapper_sidebar-collapsed body,
.mm-wrapper_sidebar-expanded body {
  position: relative; }

.mm-wrapper_sidebar-collapsed .mm-slideout,
.mm-wrapper_sidebar-expanded .mm-slideout {
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, transform;
  transition-property: width, transform;
  transition-property: width, transform, -webkit-transform; }

.mm-wrapper_sidebar-collapsed .mm-page,
.mm-wrapper_sidebar-expanded .mm-page {
  background: inherit;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100vh; }

.mm-wrapper_sidebar-collapsed .mm-menu_sidebar-collapsed,
.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  top: 0 !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important; }

.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidedivider .mm-divider,
.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidenavbar .mm-navbar {
  opacity: 0; }

.mm-wrapper_sidebar-collapsed .mm-slideout {
  width: calc(100% - var(--mm-sidebar-collapsed-size));
  -webkit-transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0);
  transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0); }

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
  border-right-width: 1px;
  border-right-style: solid;
  min-width: 0 !important;
  max-width: 100000px !important; }

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded.mm-menu_pageshadow:after {
  content: none;
  display: none; }

.mm-wrapper_sidebar-expanded.mm-wrapper_blocking,
.mm-wrapper_sidebar-expanded.mm-wrapper_blocking body {
  overflow: visible; }

.mm-wrapper_sidebar-expanded .mm-wrapper__blocker {
  display: none !important; }

.mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened ~ .mm-slideout {
  width: calc(100% - var(--mm-sidebar-expanded-size));
  -webkit-transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0);
  transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0); }

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
  width: var(--mm-sidebar-expanded-size); }

.mm-menu__blocker {
  background: rgba(3, 2, 1, 0);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3; }

.mm-menu_opened .mm-menu__blocker {
  display: none; }

[dir=rtl].mm-wrapper_sidebar-collapsed .mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

[dir=rtl].mm-wrapper_sidebar-expanded .mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

[dir=rtl].mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

input.mm-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background: var(--mm-color-border);
  border-radius: 34px;
  display: inline-block;
  min-width: 58px;
  width: 58px;
  height: 34px;
  margin: 0 10px;
  margin-top: calc((var(--mm-listitem-size) - 34px)/ 2);
  -webkit-transition: background-color .2s ease;
  -o-transition: background-color .2s ease;
  transition: background-color .2s ease; }

input.mm-toggle:before {
  content: '';
  background: var(--mm-color-background);
  border-radius: 34px;
  display: block;
  width: 32px;
  height: 32px;
  margin: 1px;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  -o-transition: transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease; }

input.mm-toggle:checked {
  background: #4bd963; }

input.mm-toggle:checked:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px); }

input.mm-check {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin: 0 10px;
  margin-top: calc((var(--mm-listitem-size) - 34px)/ 2); }

input.mm-check:before {
  border-color: var(--mm-color-text);
  content: '';
  display: block;
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .3;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease; }

input.mm-check:checked:before {
  opacity: 1; }

[dir=rtl] input.mm-toggle:checked ~ label.mm-toggle:before {
  float: left; }

.mm-menu_border-none .mm-listitem:after,
.mm-panel_border-none .mm-listitem:after {
  content: none; }

.mm-menu_border-full .mm-listitem:after,
.mm-panel_border-full .mm-listitem:after {
  left: 0 !important; }

.mm-menu_border-offset .mm-listitem:after,
.mm-panel_border-offset .mm-listitem:after {
  right: 20px; }

.mm-menu_fx-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease; }

.mm-wrapper_opened .mm-menu_fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center; }

.mm-wrapper_opening .mm-menu_fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

.mm-wrapper_opened .mm-menu_fx-menu-zoom.mm-menu_position-right {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center; }

.mm-wrapper_opening .mm-menu_fx-menu-zoom.mm-menu_position-right {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

.mm-menu_fx-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease; }

.mm-wrapper_opened .mm-menu_fx-menu-slide {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0); }

.mm-wrapper_opening .mm-menu_fx-menu-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-wrapper_opened .mm-menu_fx-menu-slide.mm-menu_position-right {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0); }

.mm-wrapper_opening .mm-menu_fx-menu-slide.mm-menu_position-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_fx-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease; }

.mm-wrapper_opening .mm-menu_fx-menu-fade {
  opacity: 1; }

.mm-menu_fx-panels-none .mm-panel,
.mm-panel_fx-none {
  -webkit-transition-property: none;
  -o-transition-property: none;
  transition-property: none; }

.mm-menu_fx-panels-none .mm-panel.mm-panel_opened-parent,
.mm-panel_fx-none.mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_fx-panels-zoom .mm-panel,
.mm-panel_fx-zoom {
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0); }

.mm-menu_fx-panels-zoom .mm-panel.mm-panel_opened,
.mm-panel_fx-zoom.mm-panel_opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

.mm-menu_fx-panels-zoom .mm-panel.mm-panel_opened-parent,
.mm-panel_fx-zoom.mm-panel_opened-parent {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0); }

.mm-menu_fx-panels-slide-0 .mm-panel_opened-parent,
.mm-panel_fx-slide-0.mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_fx-panels-slide-100 .mm-panel_opened-parent,
.mm-panel_fx-slide-100.mm-panel_opened-parent {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu_fx-panels-slide-up .mm-panel,
.mm-panel_fx-slide-up {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.mm-menu_fx-panels-slide-up .mm-panel_opened,
.mm-menu_fx-panels-slide-up .mm-panel_opened-parent,
.mm-panel_fx-slide-up.mm-panel_opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_fx-panels-slide-right .mm-panel,
.mm-panel_fx-slide-right {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu_fx-panels-slide-right .mm-panel_opened,
.mm-menu_fx-panels-slide-right .mm-panel_opened-parent,
.mm-panel_fx-slide-right.mm-panel_opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

[class*=mm-menu_fx-listitems-] .mm-listitem {
  -webkit-transition: none .4s ease;
  -o-transition: none .4s ease;
  transition: none .4s ease; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(1) {
  -webkit-transition-delay: 50ms;
  -o-transition-delay: 50ms;
  transition-delay: 50ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(2) {
  -webkit-transition-delay: .1s;
  -o-transition-delay: .1s;
  transition-delay: .1s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(3) {
  -webkit-transition-delay: 150ms;
  -o-transition-delay: 150ms;
  transition-delay: 150ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(4) {
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(5) {
  -webkit-transition-delay: 250ms;
  -o-transition-delay: 250ms;
  transition-delay: 250ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(6) {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(7) {
  -webkit-transition-delay: 350ms;
  -o-transition-delay: 350ms;
  transition-delay: 350ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(8) {
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(9) {
  -webkit-transition-delay: 450ms;
  -o-transition-delay: 450ms;
  transition-delay: 450ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(10) {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(11) {
  -webkit-transition-delay: 550ms;
  -o-transition-delay: 550ms;
  transition-delay: 550ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(12) {
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(13) {
  -webkit-transition-delay: 650ms;
  -o-transition-delay: 650ms;
  transition-delay: 650ms; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(14) {
  -webkit-transition-delay: .7s;
  -o-transition-delay: .7s;
  transition-delay: .7s; }

[class*=mm-menu_fx-listitems-] .mm-listitem:nth-child(15) {
  -webkit-transition-delay: 750ms;
  -o-transition-delay: 750ms;
  transition-delay: 750ms; }

.mm-menu_fx-listitems-slide .mm-listitem {
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0; }

.mm-wrapper_opening .mm-menu_fx-listitems-slide .mm-panel_opened .mm-listitem {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.mm-menu_fx-listitems-fade .mm-listitem {
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  opacity: 0; }

.mm-wrapper_opening .mm-menu_fx-listitems-fade .mm-panel_opened .mm-listitem {
  opacity: 1; }

.mm-menu_fx-listitems-drop .mm-listitem {
  -webkit-transition-property: opacity, top;
  -o-transition-property: opacity, top;
  transition-property: opacity, top;
  opacity: 0;
  top: -25%; }

.mm-wrapper_opening .mm-menu_fx-listitems-drop .mm-panel_opened .mm-listitem {
  opacity: 1;
  top: 0; }

.mm-menu_fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px; }

.mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
  -webkit-transform: translate3d(100vw, 0, 0);
  transform: translate3d(100vw, 0, 0); }

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen ~ .mm-slideout {
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0); } }

.mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0); } }

.mm-menu_fullscreen.mm-menu_position-top {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px; }

.mm-menu_fullscreen.mm-menu_position-bottom {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px; }

.mm-menu_listview-justify .mm-panels > .mm-panel:after,
.mm-menu_listview-justify .mm-panels > .mm-panel:before,
.mm-panels > .mm-panel_listview-justify:after,
.mm-panels > .mm-panel_listview-justify:before {
  content: none;
  display: none; }

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listview,
.mm-panels > .mm-panel_listview-justify .mm-listview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listitem,
.mm-panels > .mm-panel_listview-justify .mm-listitem {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 44px; }

.mm-menu_listview-justify .mm-panels > .mm-panel .mm-listitem__text,
.mm-panels > .mm-panel_listview-justify .mm-listitem__text {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.mm-listview_inset {
  list-style: inside disc;
  width: 100%;
  padding: 0 30px 15px 30px;
  margin: 0; }

.mm-listview_inset .mm-listitem {
  padding: 5px 0; }

.mm-listitem_spacer {
  padding-top: 44px;
  padding-top: var(--mm-listitem-size); }

.mm-listitem_spacer > .mm-listitem__btn {
  top: 44px;
  top: var(--mm-listitem-size); }

.mm-menu_multiline .mm-listitem__text,
.mm-panel_multiline .mm-listitem__text {
  -o-text-overflow: clip;
  text-overflow: clip;
  white-space: normal; }

[class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
  opacity: 0; }

.mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened ~ .mm-wrapper__blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  -o-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s; }

.mm-menu_opened.mm-menu_pagedim ~ .mm-wrapper__blocker {
  background: inherit; }

.mm-menu_opened.mm-menu_pagedim-black ~ .mm-wrapper__blocker {
  background: #000; }

.mm-menu_opened.mm-menu_pagedim-white ~ .mm-wrapper__blocker {
  background: #fff; }

.mm-menu_popup {
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  z-index: 2;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.mm-menu_popup.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

.mm-menu_popup.mm-menu_opened ~ .mm-wrapper__blocker {
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1; }

.mm-wrapper_opening .mm-menu_popup {
  opacity: 1; }

.mm-menu_position-right {
  left: auto;
  right: 0; }

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0); } }

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0); } }

.mm-menu_position-bottom,
.mm-menu_position-front,
.mm-menu_position-top {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease; }

.mm-menu_position-bottom.mm-menu_opened,
.mm-menu_position-front.mm-menu_opened,
.mm-menu_position-top.mm-menu_opened {
  z-index: 2; }

.mm-menu_position-bottom.mm-menu_opened ~ .mm-slideout,
.mm-menu_position-front.mm-menu_opened ~ .mm-slideout,
.mm-menu_position-top.mm-menu_opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

.mm-menu_position-bottom.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-front.mm-menu_opened ~ .mm-wrapper__blocker,
.mm-menu_position-top.mm-menu_opened ~ .mm-wrapper__blocker {
  z-index: 1; }

.mm-menu_position-front {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu_position-front.mm-menu_position-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-menu_position-bottom,
.mm-menu_position-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.mm-menu_position-top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.mm-menu_position-top {
  height: 80vh;
  min-height: 140px;
  max-height: 880px; }

.mm-menu_position-bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: auto; }

.mm-menu_position-bottom {
  height: 80vh;
  min-height: 140px;
  max-height: 880px; }

.mm-wrapper_opening .mm-menu_position-bottom,
.mm-wrapper_opening .mm-menu_position-front,
.mm-wrapper_opening .mm-menu_position-top {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu_shadow-page:after {
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow);
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 100;
  -webkit-clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);
  clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%); }

.mm-menu_shadow-page.mm-menu_position-right:after {
  left: auto;
  right: 100%;
  -webkit-clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%);
  clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%); }

.mm-menu_shadow-page.mm-menu_position-front:after {
  content: none;
  display: none; }

.mm-menu_shadow-menu {
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow); }

.mm-menu_shadow-panels .mm-panels > .mm-panel {
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow); }

.mm-menu_theme-white {
  --mm-color-border: rgba(0, 0, 0, 0.1);
  --mm-color-button: rgba(0, 0, 0, 0.3);
  --mm-color-text: rgba(0, 0, 0, 0.7);
  --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
  --mm-color-background: #fff;
  --mm-color-background-highlight: rgba(0, 0, 0, 0.06);
  --mm-color-background-emphasis: rgba(0, 0, 0, 0.03);
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
; }

.mm-menu_theme-dark {
  --mm-color-border: rgba(0, 0, 0, 0.3);
  --mm-color-button: rgba(255, 255, 255, 0.4);
  --mm-color-text: rgba(255, 255, 255, 0.85);
  --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
  --mm-color-background: #333;
  --mm-color-background-highlight: rgba(255, 255, 255, 0.08);
  --mm-color-background-emphasis: rgba(0, 0, 0, 0.1);
  --mm-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
; }

.mm-menu_theme-black {
  --mm-color-border: rgba(255, 255, 255, 0.25);
  --mm-color-button: rgba(255, 255, 255, 0.4);
  --mm-color-text: rgba(255, 255, 255, 0.75);
  --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
  --mm-color-background: #000;
  --mm-color-background-highlight: rgba(255, 255, 255, 0.2);
  --mm-color-background-emphasis: rgba(255, 255, 255, 0.15);
  --mm-shadow: none
; }

.mm-menu_tileview .mm-listview,
.mm-panel_tileview .mm-listview {
  margin: 0 !important; }

.mm-menu_tileview .mm-listview:after,
.mm-panel_tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both; }

.mm-menu_tileview .mm-listitem,
.mm-panel_tileview .mm-listitem {
  padding: 0;
  float: left;
  position: relative;
  width: 50%;
  height: 0;
  padding-top: 50%; }

.mm-menu_tileview .mm-listitem:after,
.mm-panel_tileview .mm-listitem:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1; }

.mm-menu_tileview .mm-listitem.mm-tile-xs,
.mm-panel_tileview .mm-listitem.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%; }

.mm-menu_tileview .mm-listitem.mm-tile-s,
.mm-panel_tileview .mm-listitem.mm-tile-s {
  width: 25%;
  padding-top: 25%; }

.mm-menu_tileview .mm-listitem.mm-tile-l,
.mm-panel_tileview .mm-listitem.mm-tile-l {
  width: 75%;
  padding-top: 75%; }

.mm-menu_tileview .mm-listitem.mm-tile-xl,
.mm-panel_tileview .mm-listitem.mm-tile-xl {
  width: 100%;
  padding-top: 100%; }

.mm-menu_tileview .mm-listitem__text,
.mm-panel_tileview .mm-listitem__text {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0 10px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0; }

.mm-menu_tileview .mm-listitem__btn,
.mm-panel_tileview .mm-listitem__btn {
  width: auto; }

.mm-menu_tileview .mm-listitem__btn:after,
.mm-menu_tileview .mm-listitem__btn:before,
.mm-panel_tileview .mm-listitem__btn:after,
.mm-panel_tileview .mm-listitem__btn:before {
  content: none;
  display: none; }

.mm-menu_tileview .mm-divider,
.mm-panel_tileview .mm-divider {
  display: none; }

.mm-menu_tileview .mm-panel,
.mm-panel_tileview {
  padding-left: 0;
  padding-right: 0; }

.mm-menu_tileview .mm-panel:after,
.mm-menu_tileview .mm-panel:before,
.mm-panel_tileview:after,
.mm-panel_tileview:before {
  content: none;
  display: none; }

body.modal-open .mm-slideout {
  z-index: unset; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}
*/
.slick-loading .slick-list {
  background: #fff slick-image-url("../img/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*! jQuery UI - v1.11.0 - 2014-08-12
* http://jqueryui.com
* Includes: core.css, autocomplete.css, menu.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */ }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

.ui-front {
  z-index: 100; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important; }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default; }

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none; }

.ui-menu .ui-menu {
  position: absolute; }

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .4em;
  cursor: pointer;
  min-height: 0;
  /* support: IE7 */
  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"); }

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

/* icon support */
.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item {
  padding-left: 2em; }

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0; }

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

body {
  font-family: 'Nunito', sans-serif;
  color: #212121;
  background-color: #fff;
  letter-spacing: 0.04em; }

h1,
h2,
h3 {
  font-family: 'Philosopher', sans-serif; }

pre {
  font-family: 'Nunito', sans-serif; }

a {
  transition: .25s ease-in-out;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

p.h2,
p.h3,
p.h4,
p.h5,
p.h6 {
  line-height: 1.5; }

.btn {
  padding: 15px 43px;
  letter-spacing: 0.1em; }

.font-phil {
  font-family: 'Philosopher', sans-serif; }

.fs-14 {
  font-size: 14px; }

path,
circle,
stroke,
svg,
img,
button {
  transition: .25s ease-in-out; }

:active,
:hover,
:focus,
button:focus {
  outline: 0;
  outline-offset: 0; }

.bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.bg-start {
  position: relative; }
  .bg-start:before, .bg-start:after {
    position: absolute;
    content: ''; }

.bg-before {
  position: relative; }
  .bg-before:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.title-icon {
  position: relative; }
  .title-icon:before {
    position: absolute;
    content: '';
    top: 0px;
    left: -320px;
    width: 268px;
    height: 58px;
    background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E"); }

input,
textarea {
  background: transparent !important;
  text-align: center;
  width: 100%;
  border-radius: 3px; }

label.error {
  display: none !important; }

input.error,
textarea.error {
  border: 1px solid #dc3545 !important; }

img {
  opacity: 1; }

img[data-src] {
  opacity: 0; }

.gray-sm {
  font-weight: 800;
  font-size: 13px;
  color: #B4B4B4; }

.item address {
  position: relative; }
  .item address:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.74398 0.00458131C4.16777 0.130062 1.19683 2.92016 0.853605 6.4816C0.783483 7.19389 0.82408 7.88403 0.949561 8.54465C0.949561 8.54465 0.960633 8.62215 0.997539 8.76977C1.10826 9.26432 1.27433 9.74409 1.48101 10.1943C2.20068 11.8994 3.86514 14.7522 7.60004 17.8561C7.82886 18.048 8.16471 18.048 8.39721 17.8561C12.1321 14.7559 13.7966 11.9031 14.5199 10.1907C14.7303 9.7404 14.8927 9.26432 15.0034 8.76608C15.0366 8.62215 15.0514 8.54096 15.0514 8.54096C15.1363 8.09808 15.1806 7.64414 15.1806 7.17912C15.1806 3.12684 11.8258 -0.139352 7.74398 0.00458131ZM7.99863 10.7516C6.07213 10.7516 4.511 9.19051 4.511 7.26401C4.511 5.33751 6.07213 3.77638 7.99863 3.77638C9.92513 3.77638 11.4863 5.33751 11.4863 7.26401C11.4863 9.19051 9.92513 10.7516 7.99863 10.7516Z' fill='%23C8AF43' /%3E%3C/svg%3E"); }
  .item address span {
    padding-left: 25px;
    display: inline-block; }

.modal-dialog {
  max-width: 940px; }
  .modal-dialog form {
    max-width: 540px; }
  .modal-dialog input {
    border: 1px solid #69380D;
    border-radius: 3px; }
  .modal-dialog .dropdown-item {
    cursor: pointer; }
  .modal-dialog .dropdown-item.active,
  .modal-dialog .dropdown-item:active {
    background-color: #C8AF43; }
  .modal-dialog .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 175, 67, 0.5); }
  .modal-dialog #dropdown {
    display: block; }
  .modal-dialog ::placeholder {
    color: #212121;
    opacity: .7; }
  .modal-dialog :-ms-input-placeholder {
    color: #212121;
    opacity: .7; }
  .modal-dialog ::-ms-input-placeholder {
    color: #212121;
    opacity: .7; }
  .modal-dialog :focus::placeholder {
    color: #212121;
    opacity: 1; }
  .modal-dialog :focus::-ms-input-placeholder {
    color: #212121;
    opacity: 1; }
  .modal-dialog :focus::-ms-input-placeholder {
    color: #212121;
    opacity: 1; }

#dropdown {
  display: none; }

.slider {
  opacity: 0; }

.slick-arrow {
  z-index: 10;
  height: 30px;
  width: 30px; }
  .slick-arrow:before {
    content: ''; }

.slick-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492 492' width='30'%3E%3Cpath fill='%23C8AF43' d='M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z'/%3E%3C/svg%3E"); }

.slick-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492.004 492.004' width='30'%3E%3Cpath fill='%23C8AF43' d='M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z'/%3E%3C/svg%3E"); }

@media (max-width: 576px) {
  .slick-list {
    margin: auto;
    max-width: 90%; }
    .slick-list .item {
      max-width: 90%; } }

header {
  position: absolute;
  z-index: 10; }
  header .info {
    background: rgba(60, 28, 0, 0.4); }
  header .menu {
    padding: 6px 0;
    z-index: 9999;
    transition: .25s ease-in-out; }
    header .menu.active > div {
      transition: 0s;
      position: relative;
      right: 8.5px; }
    header .menu.menu-down {
      padding: 8px 0;
      transform: translate3d(0, 0, 0);
      position: fixed;
      top: 0;
      background: rgba(60, 28, 0, 0.6); }
      header .menu.menu-down .logo svg {
        width: 85px;
        height: 70px; }
    header .menu.menu-up {
      transform: translate3d(0, -100%, 0); }
  header a {
    color: #fff; }
    header a:hover {
      color: #C8AF43; }
  header .social svg:hover path {
    fill: #C8AF43; }
  header li a.current {
    color: #C8AF43; }

.lang {
  color: #fff;
  cursor: pointer; }
  .lang .wrap:hover span {
    color: #C8AF43; }
  .lang .wrap:hover path {
    stroke: #C8AF43; }
  .lang .wrap.show span {
    color: #C8AF43; }
  .lang .wrap.show path {
    stroke: #C8AF43; }
  .lang span {
    transition: .25s ease-in-out;
    min-width: 30px; }
  .lang svg {
    margin-top: -8px; }
  .lang ul {
    left: -8px;
    top: 25px;
    background: #C8AF43;
    display: none;
    width: calc(100% + 16px); }
    .lang ul.show {
      display: block; }
  .lang li {
    margin-bottom: 4px; }
    .lang li a:hover {
      color: #fff;
      text-shadow: 0 0 0.7px #fff, 0 0 0.7px #fff; }
    .lang li:last-child {
      margin-bottom: 0; }

.fix-menu {
  background: rgba(60, 28, 0, 0.6); }

.blog-page header,
.blog-page-inside header,
.privacy-page header,
.editor-page header {
  position: relative;
  height: 150px; }
  .blog-page header .info,
  .blog-page-inside header .info,
  .privacy-page header .info,
  .editor-page header .info {
    background: rgba(60, 28, 0, 0.1); }
  .blog-page header .menu,
  .blog-page-inside header .menu,
  .privacy-page header .menu,
  .editor-page header .menu {
    background: rgba(60, 28, 0, 0.03); }
  .blog-page header a,
  .blog-page-inside header a,
  .privacy-page header a,
  .editor-page header a {
    color: #212121; }
    .blog-page header a:hover,
    .blog-page-inside header a:hover,
    .privacy-page header a:hover,
    .editor-page header a:hover {
      color: #C8AF43; }
  .blog-page header .lang a,
  .blog-page-inside header .lang a,
  .privacy-page header .lang a,
  .editor-page header .lang a {
    color: #fff; }
  .blog-page header .social path,
  .blog-page-inside header .social path,
  .privacy-page header .social path,
  .editor-page header .social path {
    fill: #212121; }
  .blog-page header .logo .text,
  .blog-page-inside header .logo .text,
  .privacy-page header .logo .text,
  .editor-page header .logo .text {
    fill: #212121; }
  .blog-page header .menu-down,
  .blog-page-inside header .menu-down,
  .privacy-page header .menu-down,
  .editor-page header .menu-down {
    background: rgba(246, 246, 246, 0.95); }

.blog-page .lang,
.blog-page-inside .lang,
.privacy-page .lang,
.editor-page .lang {
  color: #212121; }
  .blog-page .lang path,
  .blog-page-inside .lang path,
  .privacy-page .lang path,
  .editor-page .lang path {
    stroke: #212121; }

.blog-page .fix-menu,
.blog-page-inside .fix-menu,
.privacy-page .fix-menu,
.editor-page .fix-menu {
  background: rgba(246, 246, 246, 0.95); }
  .blog-page .fix-menu .text,
  .blog-page-inside .fix-menu .text,
  .privacy-page .fix-menu .text,
  .editor-page .fix-menu .text {
    fill: #69380D; }

.nav-icon-5 {
  width: 25px;
  height: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block; }
  .nav-icon-5 span {
    background-color: #C8AF43;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 3px;
    transition-duration: 500ms; }
    .nav-icon-5 span:nth-child(1) {
      top: 0px;
      left: 0px; }
    .nav-icon-5 span:nth-child(2) {
      top: 8px;
      left: 0px;
      opacity: 1; }
    .nav-icon-5 span:nth-child(3) {
      bottom: 1px;
      left: 0px; }
  .nav-icon-5.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 13px; }
  .nav-icon-5.open span:nth-child(2) {
    opacity: 0; }
  .nav-icon-5.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 13px; }

.mm-navbar {
  display: none; }

.mm-menu {
  padding-top: 50px;
  padding-left: 50px; }

.mm-listitem__text {
  white-space: normal; }

.mm-listitem a {
  color: #000; }
  .mm-listitem a.current {
    color: #C8AF43; }

.mm-listitem:after {
  display: none; }

.mm-sticky.mh-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  transition: transform .2s ease;
  transform: translate3d(0, 0, 0); }

.mm-sticky.mh-head.mh-scrolledout {
  transform: translate3d(0, -100%, 0); }

#menu {
  opacity: 0; }
  #menu.mm-menu_opened {
    opacity: 1; }
  #menu .btn {
    margin-left: 20px;
    max-width: 220px; }

.mm-listitem.social a, .mm-listitem.lang a {
  max-width: 60px; }
  .mm-listitem.social a.current, .mm-listitem.lang a.current {
    color: #C8AF43; }

.first-screen {
  padding: 33vh 0 15vh;
  min-height: 100vh; }
  .first-screen p {
    margin-bottom: 48px;
    font-size: 20px;
    opacity: .5; }

.about-block {
  background: linear-gradient(121.59deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%); }
  .about-block:before {
    bottom: 0;
    right: 0;
    width: 408px;
    height: 261px;
    background-image: url("data:image/svg+xml,%3Csvg width='408' height='261' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48.464 255.682C30.24 251.045-.368 234.054 3.302 200.045 8.057 156 52.363 138.284 65.102 130.5 81.74 120.333 105.51 105 81.74 81.818c-9.507 8.5-20.441 29.673 11.885 46.364 40.407 20.863 68.93 44.045 68.93 76.5 0 30.136-28.523 53.318-40.407 53.318-11.885 0-54.305-24.776-49.915-71.864 4.754-51 45.161-85.772 73.684-104.318 28.523-18.545 66.553-43.877 66.553-60.272C212.47 7.396 200.586 3 200.586 3s-11.884 4.636-11.884 18.546c0 13.909 33.007 33.906 71.307 60.272 40.408 27.818 68.93 74.182 68.93 106.637 0 32.454-28.522 67.227-45.161 67.227-11.884 2.318-30.9-19.865-30.9-44.046 0-32.454 42.784-60.272 68.93-69.545 20.917-7.418 16.639-23.182 7.131-30.136-9.507 6.954-16.163 21.79 14.261 34.772 38.031 16.228 61.8 41.728 61.8 64.909 0 23.182-16.638 34.773-28.523 44.046' stroke='%23D7D7D7' stroke-opacity='.4' stroke-width='4.5' stroke-linecap='square' stroke-linejoin='bevel'/%3E%3C/svg%3E"); }
  .about-block .text {
    max-width: 640px; }
  .about-block p {
    font-weight: bolder; }
    .about-block p:last-child {
      margin-bottom: 0;
      font-weight: 400; }

.theater {
  background: linear-gradient(97.1deg, #69380D 0.71%, #3C1C00 103.42%); }
  .theater .text {
    min-height: 210px;
    z-index: 1; }
    .theater .text:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 104px;
      background-image: url(../img/frame-w-up.png);
      background-repeat: no-repeat; }
    .theater .text:after {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 104px;
      background-image: url(../img/frame-w-dw.png);
      background-repeat: no-repeat; }
  .theater .wrap {
    left: -30px;
    width: calc(100% - 33.6px);
    z-index: -1; }
    .theater .wrap:before {
      top: 56px;
      left: 36px;
      width: 3px;
      height: calc(100% - 208px);
      background-image: url(../img/frame-w-l.png); }
    .theater .wrap:after {
      top: 56px;
      right: 0;
      width: 3px;
      height: calc(100% - 208px);
      background-image: url(../img/frame-w-l.png); }
  .theater p {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px; }
    .theater p:last-child {
      margin-bottom: 0; }

.video-wrap {
  min-height: 760px; }

.blog-block .category {
  border-radius: 50px; }

.blog-block .literature {
  background: rgba(174, 148, 30, 0.08); }
  .blog-block .literature a {
    color: #AE941E; }

.blog-block .history {
  background: rgba(215, 215, 215, 0.28); }
  .blog-block .history a {
    color: #7A7A7A; }

.blog-block .art {
  background: rgba(172, 138, 90, 0.2); }
  .blog-block .art a {
    color: #69380D; }

.blog-block p {
  margin-bottom: 0;
  display: inline;
  font-weight: lighter;
  color: #7A7A7A; }

.blog-block h3 a {
  color: #212121; }
  .blog-block h3 a:hover {
    color: #C8AF43; }

.blog-block .item:hover img {
  transform: scale(1.2); }

.blog-block .img-wrap {
  min-width: 200px;
  width: 200px;
  height: 170px;
  /*img {
            width: 200px;
            height: 170px;
        }*/ }

.info-block .text-wrap {
  min-height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1; }
  .info-block .text-wrap:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 104px;
    background-image: url(../img/frame-w-up.png);
    background-repeat: no-repeat; }
  .info-block .text-wrap:after {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 104px;
    background-image: url(../img/frame-w-dw.png);
    background-repeat: no-repeat; }

.info-block .wrap {
  left: -30px;
  width: calc(100% - 33.6px);
  z-index: -1; }
  .info-block .wrap:before {
    top: 104px;
    left: 36px;
    width: 3px;
    height: calc(100% - 208px);
    background-image: url(../img/frame-w-l.png); }
  .info-block .wrap:after {
    top: 104px;
    right: 0;
    width: 3px;
    height: calc(100% - 208px);
    background-image: url(../img/frame-w-l.png); }

.info-block .text {
  top: 0;
  left: 0;
  background: rgba(172, 138, 90, 0.8);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  letter-spacing: 0.05em;
  opacity: 0;
  transition: .25s ease-in-out; }

.info-block .img-wrap:hover .text {
  opacity: 1; }

.info-block p {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  font-size: 18px; }
  .info-block p:last-child {
    margin-bottom: 0;
    font-size: 16px; }

.info-block .row a {
  display: block;
  width: 100%;
  height: 100%; }

.clubs-block {
  background: linear-gradient(119.48deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%); }
  .clubs-block:before {
    right: 0;
    bottom: 50px;
    width: 398px;
    height: 86px;
    background-image: url("data:image/svg+xml,%3Csvg width='398' height='86' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 31.929C8.122 13.706 32.15 1.184 51.76.099c20.678-1.155 40.306 7.99 56.059 20.818 16.819 13.285 30.457 29.846 48.207 42.164 16.735 11.588 32.15 21.956 53.013 18.664 18.071-2.834 39.967-15.491 36.092-36.497-2.826-15.236-19.814-41.18-38.173-38.465-14.603 1.9-5.212 18.393.812 25.451 13.824 16 35.534 23.755 56.312 22.753 15.5-.78 30.356-6.09 43.994-13.404a186.095 186.095 0 008.731-5.005c7.344 8.89 16.329 9.773 23.909 8.399 14.772-2.664 29.832-13.727 45.247-11.148 11.032 1.833 16.447 12.386 7.665 24.484 5.364-12.335-1.591-26.707-29.273-16.39-15.787 5.887-37.547 14.473-47.615-5.006-6.126 6.024-16.075 12.132-23.012 15.542a87.808 87.808 0 01-30.323 8.79c-20.101 1.883-42.911-2.122-58.105-15.577-8.935-7.923-21.997-29.896-9.442-41.01 15.888-14.066 35.753 7.55 42.995 20.497 4.552 8.11 8.004 17.748 7.243 27.148-.88 10.977-8.19 18.664-17.023 24.025a71.955 71.955 0 01-62.556 4.598c-19.966-7.787-37.496-24.11-52.945-38.787C98.682 28.01 83.385 14.42 62.894 9.43a57.387 57.387 0 00-39.425 3.988A77.699 77.699 0 000 31.928z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E"); }
  .clubs-block .btn {
    background-color: #69380D;
    border-color: #69380D; }
    .clubs-block .btn:hover {
      background-color: #AC8A5A;
      border-color: #AC8A5A; }

.afisha .item:hover img {
  transform: scale(1.2); }

.afisha h3 a {
  color: #212121; }
  .afisha h3 a:hover {
    color: #C8AF43; }

.afisha p {
  margin-bottom: 0;
  display: inline;
  font-weight: lighter; }

.partners-block {
  background: linear-gradient(123.56deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%); }
  .partners-block .img-wrap {
    min-height: 120px; }
  .partners-block p {
    margin: 35px 0 0;
    padding: 0 10px; }
  .partners-block .slick-slide {
    margin: 0 10px; }

.contact-block form {
  max-width: 540px; }
  .contact-block form input,
  .contact-block form textarea {
    border: 1px solid rgba(255, 255, 255, 0.7);
    color: #fff; }
  .contact-block form ::placeholder {
    color: #fff;
    opacity: .7; }
  .contact-block form :-ms-input-placeholder {
    color: #fff;
    opacity: .7; }
  .contact-block form ::-ms-input-placeholder {
    color: #fff;
    opacity: .7; }
  .contact-block form :focus::placeholder {
    color: #fff;
    opacity: 1; }
  .contact-block form :focus::-ms-input-placeholder {
    color: #fff;
    opacity: 1; }
  .contact-block form :focus::-ms-input-placeholder {
    color: #fff;
    opacity: 1; }

@media (max-width: 1780px) {
  .title-icon:before {
    display: none; } }

@media (max-width: 1300px) {
  .clubs-block:before {
    display: none; } }

@media (max-width: 1200px) {
  .first-screen {
    padding: 25vh 0 20vh;
    min-height: 70vh; }
  .about-block .img {
    min-height: 500px; }
  .theater .text {
    background-size: 100%; }
  .info-block .img-wrap {
    display: inline-block; }
  .info-block .text-wrap {
    background-size: 100%; }
  .info-block .text {
    opacity: 1;
    background: rgba(172, 138, 90, 0.5); } }

@media (max-width: 992px) {
  .blog-block .item {
    justify-content: space-between; }
  .blog-block img {
    width: 100%; }
  .theater .text {
    background: none; }
  .info-block .text-wrap {
    background: none; }
  .afisha img {
    width: 100%; } }

@media (max-width: 767px) {
  h2 {
    font-size: 40px; }
  .home-page .first-screen {
    padding: 20vh 0 15vh; }
    .home-page .first-screen svg {
      width: 100%; }
  .about-block:before {
    width: 90%;
    height: 230px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    background-size: 90% 230px;
    background-repeat: no-repeat;
    background-position: center; }
  .video-wrap {
    min-height: 460px; }
  .blog-block .item {
    flex-direction: column; }
    .blog-block .item .img-wrap {
      margin-bottom: 20px;
      order: 1;
      text-align: center;
      width: auto;
      height: auto; }
      .blog-block .item .img-wrap img {
        width: 100%;
        height: auto;
        max-height: 284px; }
    .blog-block .item .text {
      order: 2; }
  .slick-prev {
    left: -5px; }
  .slick-next {
    right: -5px; } }

.about-page .first-screen h1,
.clubs-page .first-screen h1,
.courses-page .first-screen h1 {
  max-width: 650px; }

.about-page .first-screen p,
.clubs-page .first-screen p,
.courses-page .first-screen p {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  opacity: 1; }

.target-block {
  background: linear-gradient(121.59deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%); }
  .target-block h2:before {
    top: 0;
    left: -100px;
    width: 80px;
    height: 236px;
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"); }
  .target-block .col-xl-6:first-child {
    max-width: 640px; }
  .target-block .img:before {
    border: 1px solid #fff;
    top: 25px;
    left: 25px;
    width: calc(100% - 50px);
    height: calc(100% - 50px); }
  .target-block p {
    font-weight: bolder; }
    .target-block p:last-child {
      margin-bottom: 0;
      font-weight: 400; }

.who-block .img-wrap:before {
  border: 1px solid #AC8A5A;
  top: -30px;
  left: -30px;
  width: 100%;
  height: calc(100% + 60px); }

.who-block .text:before {
  position: absolute;
  content: '';
  bottom: -100px;
  right: 0;
  width: 268px;
  height: 58px;
  background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E"); }

.who-block p:last-child {
  margin-bottom: 0;
  font-weight: 400; }

.editors {
  background: linear-gradient(108.18deg, #69380D 2.68%, #3C1C00 100%); }
  .editors p {
    margin-bottom: 0;
    color: #fff; }
  .editors img {
    width: 149px;
    height: 149px; }

.editor-block .img-wrap:before {
  border: 1px solid #AC8A5A;
  top: -30px;
  left: -30px;
  width: 100%;
  height: calc(100% + 60px); }

.editor-block h1:before {
  top: -50px;
  right: 0;
  width: 268px;
  height: 58px;
  background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E"); }

.editor-block .d-none p:nth-child(2), .editor-block .d-none p:nth-child(3) {
  font-size: 18px; }

.editor-block .d-none p:nth-child(4) {
  margin-bottom: 0; }

.editor-info {
  background: linear-gradient(116.52deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%); }
  .editor-info .container {
    max-width: 745px; }
    .editor-info .container p:nth-child(3) {
      margin-bottom: 48px; }
    .editor-info .container p:nth-child(4) {
      margin-bottom: 24px;
      text-align: center; }
    .editor-info .container p:nth-child(5) {
      margin-bottom: 24px;
      font-weight: bolder;
      font-style: italic;
      text-align: center;
      position: relative; }
      .editor-info .container p:nth-child(5):before, .editor-info .container p:nth-child(5):after {
        position: absolute;
        content: ''; }
    .editor-info .container p:nth-child(6) {
      text-align: center; }
  .editor-info p.bg-start:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 236px;
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    opacity: .7; }
  .editor-info .d-lg-none p:nth-child(2), .editor-info .d-lg-none p:nth-child(3) {
    font-size: 18px; }

.clubs-block .item:hover img {
  transform: scale(1.2); }

.clubs-block .img-wrap svg {
  position: absolute;
  bottom: 0;
  right: 0; }

.clubs-block button:hover {
  color: #69380D !important; }

.courses-block .container:before {
  top: 40px;
  left: -100px;
  width: 80px;
  height: 236px;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"); }

.courses-block .item {
  border: 1px solid #AC8A5A;
  border-radius: 3px;
  min-height: 423px;
  height: 100%; }
  .courses-block .item:hover img {
    transform: scale(1.2); }

.courses-block .wrap-item:first-child {
  border-right: 1px dashed #69380D; }

.courses-block button:hover {
  color: #69380D !important; }

.courses-block p {
  margin-bottom: 24px;
  font-weight: bolder;
  color: #69380D; }

.courses-block .wrap-item p:first-child {
  margin-bottom: 16px; }

.courses-block .wrap-item p:nth-child(2), .courses-block .wrap-item p:nth-child(3) {
  margin-bottom: 8px;
  font-size: 14px;
  color: #343a40; }

.courses-info .block {
  min-height: 210px; }
  .courses-info .block:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 92px;
    background-image: url(../img/frame-up.png);
    background-repeat: no-repeat; }
  .courses-info .block:after {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 109px;
    background-image: url(../img/frame-dw.png);
    background-repeat: no-repeat; }
  .courses-info .block p {
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 700; }

.courses-info .wrap {
  left: -30px;
  width: calc(100% - 34px);
  z-index: -1; }
  .courses-info .wrap:before {
    top: 32px;
    left: 36px;
    width: 3px;
    height: calc(100% - 201px);
    background-image: url(../img/frame-l.png); }
  .courses-info .wrap:after {
    top: 32px;
    right: 0;
    width: 3px;
    height: calc(100% - 201px);
    background-image: url(../img/frame-l.png); }

.courses-info p {
  margin-left: auto;
  margin-right: auto;
  max-width: 930px; }

.courses-info .btn {
  position: relative;
  z-index: 2; }

.courses-page .contact-block #jarallax-container-2 {
  display: none; }

.courses-page .contact-block h2 {
  color: #212121 !important;
  position: relative; }
  .courses-page .contact-block h2:before {
    position: absolute;
    content: '';
    top: 0px;
    left: -120px;
    width: 268px;
    height: 58px;
    background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E"); }

.courses-page .contact-block form input,
.courses-page .contact-block form textarea {
  border: 1px solid #7A7A7A;
  color: #212121; }

.courses-page .contact-block form ::placeholder {
  color: #212121;
  opacity: .7; }

.courses-page .contact-block form :-ms-input-placeholder {
  color: #212121;
  opacity: .7; }

.courses-page .contact-block form ::-ms-input-placeholder {
  color: #212121;
  opacity: .7; }

.courses-page .contact-block form :focus::placeholder {
  color: #212121;
  opacity: 1; }

.courses-page .contact-block form :focus::-ms-input-placeholder {
  color: #212121;
  opacity: 1; }

.courses-page .contact-block form :focus::-ms-input-placeholder {
  color: #212121;
  opacity: 1; }

.blog-page-inside h1 {
  font-size: 40px; }

.blog-page-inside p {
  color: #000;
  font-size: 15px; }

.blog-page-inside .text a {
  color: #C8AF43; }
  .blog-page-inside .text a:hover {
    color: #69380D; }

.blog-page-inside .info-wrap {
  margin-top: -26px; }

.blog-page-inside .like svg {
  cursor: pointer; }
  .blog-page-inside .like svg:hover circle {
    stroke: #C8AF43; }
  .blog-page-inside .like svg:hover path {
    fill: #C8AF43; }
  .blog-page-inside .like svg.active circle {
    stroke: #C8AF43; }
  .blog-page-inside .like svg.active path {
    fill: #C8AF43; }

.blog-page-inside .share svg {
  cursor: pointer; }
  .blog-page-inside .share svg:hover path {
    fill: #fff; }
  .blog-page-inside .share svg:hover circle {
    fill: #C8AF43;
    stroke: #C8AF43; }

.blog-page-inside .home-btn a:hover path {
  fill: #7b623e; }

.similar .date {
  font-size: 13px; }

.similar .item:hover img {
  transform: scale(1.2); }

.similar h3 a {
  color: #212121; }
  .similar h3 a:hover {
    color: #C8AF43; }

.similar p {
  margin-bottom: 0;
  display: inline;
  font-weight: lighter; }

.block-events {
  background: linear-gradient(99.73deg, rgba(243, 243, 243, 0.4) 0.54%, rgba(215, 215, 215, 0.4) 105.77%); }
  .block-events p {
    margin-bottom: 0;
    color: #7A7A7A;
    font-size: 13px;
    width: 140px;
    min-width: 140px;
    text-transform: uppercase; }
  .block-events .item-wrap {
    border-bottom: 1px solid #E5E5E5; }
  .block-events .btn {
    font-size: 0.9rem;
    width: 100%;
    min-width: 255px; }
    .block-events .btn:hover {
      color: #fff; }

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #212121;
  border-radius: 50%;
  min-height: 48px;
  min-width: 48px; }
  .page-link:hover {
    background-color: #C8AF43;
    color: #fff; }

.page-item {
  margin: 0 3px; }
  .page-item.active .page-link {
    background: linear-gradient(141.27deg, #C8AF43 15.23%, #AE941E 94.63%);
    color: #fff; }

.editor img {
  min-width: 75px;
  width: 75px;
  height: 75px; }

.editor p {
  max-width: 445px; }

.contact-main {
  padding: 28vh 0 15vh;
  min-height: 785px; }
  .contact-main .container {
    max-width: 950px;
    background: rgba(172, 138, 90, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  .contact-main .info a {
    color: #fff; }
    .contact-main .info a:hover {
      color: #C8AF43; }
      .contact-main .info a:hover path {
        fill: #C8AF43; }
  .contact-main #map {
    width: 60%; }
    .contact-main #map .gm-style-cc,
    .contact-main #map .gm-style-mtc,
    .contact-main #map .gm-svpc {
      display: none; }

.contact-us .container {
  max-width: 540px; }

.contact-us form input,
.contact-us form textarea {
  border: 1px solid #7A7A7A;
  color: #212121; }

.contact-us form ::placeholder {
  color: #212121;
  opacity: .7; }

.contact-us form :-ms-input-placeholder {
  color: #212121;
  opacity: .7; }

.contact-us form ::-ms-input-placeholder {
  color: #212121;
  opacity: .7; }

.contact-us form :focus::placeholder {
  color: #212121;
  opacity: 1; }

.contact-us form :focus::-ms-input-placeholder {
  color: #212121;
  opacity: 1; }

.contact-us form :focus::-ms-input-placeholder {
  color: #212121;
  opacity: 1; }

.privacy-page main .container {
  max-width: 750px; }

.privacy-page main h3 {
  margin-top: 35px;
  margin-bottom: 24px; }

.page-404 main {
  padding: 28vh 0 15vh;
  min-height: 100vh; }

.page-404 h1 {
  font-size: 200px; }
  .page-404 h1:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 526px;
    height: 114px;
    background-image: url("data:image/svg+xml,%3Csvg width='526' height='114' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 42.324C10.734 18.168 42.489 1.57 68.407.13c27.327-1.53 53.268 10.594 74.088 27.597 22.228 17.611 40.252 39.563 63.711 55.892 22.116 15.362 42.489 29.104 70.062 24.741 23.883-3.757 52.82-20.535 47.699-48.38-3.734-20.197-26.186-54.587-50.45-50.988-19.299 2.519-6.888 24.38 1.074 33.737 18.27 21.21 46.961 31.488 74.422 30.161 20.485-1.034 40.119-8.074 58.143-17.768a246.29 246.29 0 0011.539-6.635c9.706 11.785 21.58 12.955 31.599 11.133 19.522-3.53 39.425-18.195 59.797-14.777 14.581 2.43 21.737 16.42 10.131 32.456 7.088-16.352-2.103-35.402-38.688-21.727-20.864 7.804-49.622 19.185-62.928-6.635-8.095 7.984-21.245 16.081-30.413 20.602a115.788 115.788 0 01-40.074 11.65c-26.567 2.497-56.712-2.81-76.793-20.647-11.808-10.503-29.072-39.63-12.479-54.362 20.999-18.645 47.252 10.01 56.824 27.17 6.015 10.751 10.577 23.526 9.571 35.987-1.163 14.552-10.824 24.74-22.497 31.848a94.936 94.936 0 01-40.644 12.558 94.83 94.83 0 01-42.03-6.463c-26.388-10.324-49.556-31.96-69.973-51.416-19.679-18.735-39.895-36.751-66.976-43.363a75.636 75.636 0 00-52.105 5.285A102.736 102.736 0 000 42.324z' fill='%23AC8A5A' fill-opacity='.18'/%3E%3C/svg%3E"); }

.page-404 p {
  margin: 0 auto 48px auto;
  max-width: 420px;
  color: #fff;
  font-size: 25px; }

@media (max-width: 1780px) {
  .contact-page .title-icon:before {
    display: block; }
  .target-block h2:before {
    display: none; } }

@media (max-width: 1200px) {
  .target-block .img {
    min-height: 500px; }
  .courses-block .container {
    max-width: 580px; }
  .courses-block .item {
    min-height: 385px; }
  .courses-info .block {
    background-size: 100%; }
  .courses-page .contact-block h2:before {
    display: none; }
  .block-events {
    background: linear-gradient(99.73deg, rgba(243, 243, 243, 0.4) 0.54%, rgba(215, 215, 215, 0.4) 105.77%); }
    .block-events p {
      margin-right: 15px;
      width: auto;
      min-width: 170px; }
    .block-events .btn {
      width: auto; }
  .contact-page .title-icon:before {
    display: none; }
  .page-404 main {
    padding-top: 22vh;
    min-height: 75vh; }
  .theater .text:before, .theater .text:after, .theater .wrap, .info-block .text-wrap:before, .info-block .text-wrap:after, .info-block .wrap, .courses-info .block:before, .courses-info .block:after, .courses-info .wrap {
    display: none; } }

@media (max-width: 992px) {
  .who-block .text:before {
    display: none; }
  .courses-block .container:before {
    display: none; }
  .blog-page-inside .img-wrap {
    border: 0 !important; }
  .info-wrap {
    margin-top: 0;
    border-bottom: 1px solid #e5e5e5; }
  .courses-info .block {
    background: none; }
  .block-events {
    width: 100%; }
  .contact-main .container {
    max-width: 550px; }
  .contact-main #map {
    min-height: 400px;
    width: 100%; }
  .contact-main .info {
    max-width: 215px; } }

@media (max-width: 767px) {
  .target-block .img {
    min-height: 300px; }
  .who-block .img-wrap:before,
  .editor-block .img-wrap:before {
    top: -15px;
    left: -10px;
    height: calc(100% + 30px); }
  .courses-block img {
    width: 100%; }
  .blog-page-inside .like,
  .blog-page-inside .share {
    border-bottom: 1px solid #e5e5e5; }
  .similar img {
    width: 100%; }
  .block-events p {
    width: 140px;
    min-width: 140px; }
  .contact-main {
    padding: 107px 0 0; }
  .page-404 main {
    padding-top: 27vh; }
  .page-404 h1 {
    font-size: 100px; }
    .page-404 h1:before {
      background-size: 95% 80px;
      width: 95%;
      height: 80px;
      background-repeat: no-repeat;
      background-position: center; } }

@media (max-width: 576px) {
  .courses-block .wrap-item:first-child {
    border-right: 0;
    border-bottom: 1px dashed #69380D; }
  .courses-block .item {
    min-height: auto; }
  .block-events .btn {
    width: 100%; }
  .privacy-page h1 {
    font-size: 28px; }
  .contact-main .info {
    max-width: 100%; } }

footer {
  background-color: #3C1C00; }
  footer .container {
    max-width: 960px; }
  footer h6 {
    letter-spacing: 0.2em; }
  footer li {
    margin-bottom: 10px; }
    footer li:last-child {
      margin-bottom: 0; }
    footer li a {
      font-size: 14px; }
  footer a {
    color: #fff; }
    footer a:hover {
      color: #C8AF43; }
