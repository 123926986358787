.about-page,
.clubs-page,
.courses-page {
    .first-screen {
        h1 {
            max-width: 650px;
        }

        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 900px;
            opacity: 1;
        }
    }
}

.target-block {
    background: linear-gradient(121.59deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%);

    h2 {
        &:before {
            top: 0;
            left: -100px;
            width: 80px;
            height: 236px;
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }

    .col-xl-6 {
        &:first-child {
            max-width: 640px;
        }
    }

    .img {
        &:before {
            border: 1px solid $white;
            top: 25px;
            left: 25px;
            width: calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    p {
        font-weight: bolder;

        &:last-child {
            margin-bottom: 0;
            font-weight: 400;
        }
    }
}

.who-block {
    .img-wrap {
        &:before {
            border: 1px solid $orange2;
            top: -30px;
            left: -30px;
            width: 100%;
            height: calc(100% + 60px);
        }
    }

    .text {
        &:before {
            position: absolute;
            content: '';
            bottom: -100px;
            right: 0;
            width: 268px;
            height: 58px;
            background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E");
        }
    }

    p {

        &:last-child {
            margin-bottom: 0;
            font-weight: 400;
        }
    }
}

.editors {
    background: linear-gradient(108.18deg, #69380D 2.68%, #3C1C00 100%);

    p {
        margin-bottom: 0;
        color: $white;
    }

    img {
        width: 149px;
        height: 149px;
    }
}

.editor-block {
    .img-wrap {
        &:before {
            border: 1px solid $orange2;
            top: -30px;
            left: -30px;
            width: 100%;
            height: calc(100% + 60px);
        }
    }

    h1 {
        &:before {
            top: -50px;
            right: 0;
            width: 268px;
            height: 58px;
            background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E");
        }
    }

    .d-none {
        p {

            &:nth-child(2),
            &:nth-child(3) {
                font-size: 18px;
            }

            &:nth-child(4) {
                margin-bottom: 0;
            }
        }
    }
}

.editor-info {
    background: linear-gradient(116.52deg, rgba(255, 255, 255, 0.4) -0.31%, rgba(215, 215, 215, 0.4) 105.83%);

    .container {
        max-width: 745px;

        p {
            &:nth-child(3) {
                margin-bottom: 48px;
            }

            &:nth-child(4) {
                margin-bottom: 24px;
                text-align: center;
            }

            &:nth-child(5) {
                margin-bottom: 24px;
                font-weight: bolder;
                font-style: italic;
                text-align: center;
                position: relative;

                &:before,
                &:after {
                    position: absolute;
                    content: '';
                }
            }

            &:nth-child(6) {
                text-align: center;
            }
        }
    }

    p.bg-start {
        &:before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 236px;
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            opacity: .7;
        }
    }

    .d-lg-none {
        p {

            &:nth-child(2),
            &:nth-child(3) {
                font-size: 18px;
            }
        }
    }
}

.clubs-block {
    .item {
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }

    .img-wrap {
        svg {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    button {
        &:hover {
            color: $orange !important;
        }
    }
}

.courses-block {
    .container {
        &:before {
            top: 40px;
            left: -100px;
            width: 80px;
            height: 236px;
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M48.188 0C-1.215 19.698-5.667 100.491 53.764 109.098c9.677 1.409 29.016-2.5 25.233-16.668-2.947-11-20.28-14.5-29.852-15.425C28.94 75.126 8.963 90.233 11.41 111.386c1.428 12.289 7.414 20.016 17.455 26.502 5.09 1.954 10.635 4.409 16.24 2.788 8.553-2.516 4.558-12.304-2.111-14.107-13.156-3.561-25.674 9.197-23.532 22.001 1.519 9.092 18.564 26.517 28.15 17.304 4.861-4.545-1.762-7.909-5.88-7.576a20.532 20.532 0 00-9.705 3.233 20.465 20.465 0 00-6.93 7.51 19.312 19.312 0 003.059 21.889 19.397 19.397 0 006.346 4.537c2.856 1.288 12.366-1.515 8.127-5.228-4.238-3.712-10.102 5.577-10.634 8.759-1.352 13.167 9.115 24.501.167 37.063 7.11-11.486-.425-21.79-1.853-33.518-.714-5.849 2.597-12.985 8.553-14.44 7.368-1.833 7.869 6.425 2.278 8.637-11.606 4.546-22.119-9.576-20.038-22.82 1.307-8.334 6.7-12.667 13.263-15.804 7.596-3.652 20.099-.682 17 5.834-6.776 14.274-31.31-.561-34.106-12.016-3.585-14.91 7.733-27.835 22.56-26.38 5.712.56 15.01 7.773 8.37 13.637a25.89 25.89 0 01-16.802 1.333c-11.85-2.758-22.27-12.334-25.02-24.304a35.413 35.413 0 01-.27-15.421 35.457 35.457 0 016.314-14.08 35.568 35.568 0 0111.703-10.08 35.664 35.664 0 0114.884-4.18c13.005-.894 29.548 2.834 37.737 13.865 11.713 15.743-10.862 24.91-23.107 24.092C25.827 108.552 1.382 88.203 0 59.822-1.185 33.123 19.202 5.319 48.188 0z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h80v236H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }

    .item {
        border: 1px solid $orange2;
        border-radius: 3px;
        min-height: 423px;
        height: 100%;

        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }

    .wrap-item {
        &:first-child {
            border-right: 1px dashed $orange;
        }
    }

    button {
        &:hover {
            color: $orange !important;
        }
    }

    p {
        margin-bottom: 24px;
        font-weight: bolder;
        color: $orange;
    }

    .wrap-item {
        p {
            &:first-child {
                margin-bottom: 16px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-bottom: 8px;
                font-size: 14px;
                color: $gray-800;
            }
        }
    }
}

.courses-info {
    .block {
        min-height: 210px;

        &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 92px;
            background-image: url(../img/frame-up.png);
            background-repeat: no-repeat;
        }

        &:after {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 109px;
            background-image: url(../img/frame-dw.png);
            background-repeat: no-repeat;
        }

        p {
            margin-bottom: 50px;
            font-size: 18px;
            font-weight: 700;
        }
    }

    .wrap {
        left: -30px;
        width: calc(100% - 34px);
        z-index: -1;

        &:before {
            top: 32px;
            left: 36px;
            width: 3px;
            height: calc(100% - 201px);
            background-image: url(../img/frame-l.png);
        }

        &:after {
            top: 32px;
            right: 0;
            width: 3px;
            height: calc(100% - 201px);
            background-image: url(../img/frame-l.png);
        }
    }

    p {
        margin-left: auto;
        margin-right: auto;
        max-width: 930px;
    }
    
    .btn{
        position: relative;
        z-index: 2;
    }
}

.courses-page {
    .contact-block {
        #jarallax-container-2 {
            display: none;
        }

        h2 {
            color: $text !important;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                top: 0px;
                left: -120px;
                width: 268px;
                height: 58px;
                background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E");
            }
        }

        form {

            input,
            textarea {
                border: 1px solid $gray-600;
                color: $text;
            }

            ::placeholder {
                color: $text;
                opacity: .7;
            }

            :-ms-input-placeholder {
                color: $text;
                opacity: .7;
            }

            ::-ms-input-placeholder {
                color: $text;
                opacity: .7;
            }

            :focus::placeholder {
                color: $text;
                opacity: 1;
            }

            :focus::-ms-input-placeholder {
                color: $text;
                opacity: 1;
            }

            :focus::-ms-input-placeholder {
                color: $text;
                opacity: 1;
            }
        }
    }
}

.blog-page-inside {
    h1 {
        font-size: 40px;
    }

    p {
        color: $black;
        font-size: 15px;
    }

    .text a {
        color: $yellow;

        &:hover {
            color: $orange;
        }
    }

    .info-wrap {
        margin-top: -26px;
    }

    .like {
        svg {
            cursor: pointer;

            &:hover {
                circle {
                    stroke: $yellow;
                }

                path {
                    fill: $yellow;
                }
            }

            &.active {
                circle {
                    stroke: $yellow;
                }

                path {
                    fill: $yellow;
                }
            }
        }
    }

    .share {
        svg {
            cursor: pointer;

            &:hover {
                path {
                    fill: $white;
                }

                circle {
                    fill: $yellow;
                    stroke: $yellow;
                }
            }
        }
    }

    .home-btn {
        a {
            &:hover {
                path {
                    fill: #7b623e;
                }
            }
        }
    }
}

.similar {
    .date {
        font-size: 13px;
    }

    .item {

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

    }

    h3 {

        a {
            color: $text;

            &:hover {
                color: $yellow;
            }
        }

    }

    p {
        margin-bottom: 0;
        display: inline;
        font-weight: lighter;
    }
}

.block-events {
    background: linear-gradient(99.73deg, rgba(243, 243, 243, 0.4) 0.54%, rgba(215, 215, 215, 0.4) 105.77%);

    p {
        margin-bottom: 0;
        color: $gray-600;
        font-size: 13px;
        width: 140px;
        min-width: 140px;
        text-transform: uppercase;
    }

    .item-wrap {
        border-bottom: 1px solid #E5E5E5;
    }

    .btn {
        font-size: 0.9rem;
        width: 100%;
        min-width: 255px;

        &:hover {
            color: $white;
        }
    }
}

.page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: $text;
    border-radius: 50%;
    min-height: 48px;
    min-width: 48px;

    &:hover {
        background-color: $yellow;
        color: $white;
    }
}

.page-item {
    margin: 0 3px;

    &.active {
        .page-link {
            background: linear-gradient(141.27deg, #C8AF43 15.23%, #AE941E 94.63%);
            color: $white;
        }
    }
}

.editor {
    img {
        min-width: 75px;
        width: 75px;
        height: 75px;
    }

    p {
        max-width: 445px;
    }
}

.contact-main {
    padding: 28vh 0 15vh;
    min-height: 785px;

    .container {
        max-width: 950px;
        background: rgba(172, 138, 90, 0.9);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .info {
        a {
            color: $white;

            &:hover {
                color: $yellow;

                path {
                    fill: $yellow;
                }
            }
        }
    }

    #map {
        width: 60%;

        .gm-style-cc,
        .gm-style-mtc,
        .gm-svpc {
            display: none;
        }
    }
}

.contact-us {
    .container {
        max-width: 540px;
    }

    form {

        input,
        textarea {
            border: 1px solid $gray-600;
            color: $text;
        }

        ::placeholder {
            color: $text;
            opacity: .7;
        }

        :-ms-input-placeholder {
            color: $text;
            opacity: .7;
        }

        ::-ms-input-placeholder {
            color: $text;
            opacity: .7;
        }

        :focus::placeholder {
            color: $text;
            opacity: 1;
        }

        :focus::-ms-input-placeholder {
            color: $text;
            opacity: 1;
        }

        :focus::-ms-input-placeholder {
            color: $text;
            opacity: 1;
        }
    }
}

.privacy-page {
    main {
        .container {
            max-width: 750px;
        }

        h3 {
            margin-top: 35px;
            margin-bottom: 24px;
        }
    }
}

.page-404 {
    main {
        padding: 28vh 0 15vh;
        min-height: 100vh;
    }

    h1 {
        font-size: 200px;

        &:before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 526px;
            height: 114px;
            background-image: url("data:image/svg+xml,%3Csvg width='526' height='114' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 42.324C10.734 18.168 42.489 1.57 68.407.13c27.327-1.53 53.268 10.594 74.088 27.597 22.228 17.611 40.252 39.563 63.711 55.892 22.116 15.362 42.489 29.104 70.062 24.741 23.883-3.757 52.82-20.535 47.699-48.38-3.734-20.197-26.186-54.587-50.45-50.988-19.299 2.519-6.888 24.38 1.074 33.737 18.27 21.21 46.961 31.488 74.422 30.161 20.485-1.034 40.119-8.074 58.143-17.768a246.29 246.29 0 0011.539-6.635c9.706 11.785 21.58 12.955 31.599 11.133 19.522-3.53 39.425-18.195 59.797-14.777 14.581 2.43 21.737 16.42 10.131 32.456 7.088-16.352-2.103-35.402-38.688-21.727-20.864 7.804-49.622 19.185-62.928-6.635-8.095 7.984-21.245 16.081-30.413 20.602a115.788 115.788 0 01-40.074 11.65c-26.567 2.497-56.712-2.81-76.793-20.647-11.808-10.503-29.072-39.63-12.479-54.362 20.999-18.645 47.252 10.01 56.824 27.17 6.015 10.751 10.577 23.526 9.571 35.987-1.163 14.552-10.824 24.74-22.497 31.848a94.936 94.936 0 01-40.644 12.558 94.83 94.83 0 01-42.03-6.463c-26.388-10.324-49.556-31.96-69.973-51.416-19.679-18.735-39.895-36.751-66.976-43.363a75.636 75.636 0 00-52.105 5.285A102.736 102.736 0 000 42.324z' fill='%23AC8A5A' fill-opacity='.18'/%3E%3C/svg%3E");
        }
    }

    p {
        margin: 0 auto 48px auto;
        max-width: 420px;
        color: $white;
        font-size: 25px;
    }
}

@media(max-width: 1780px) {
    .contact-page {
        .title-icon:before {
            display: block;
        }
    }

    .target-block {
        h2 {
            &:before {
                display: none;
            }
        }
    }
}

@media(max-width: 1200px) {
    .target-block {

        .img {
            min-height: 500px;
        }
    }

    .courses-block {
        .container {
            max-width: 580px;
        }

        .item {
            min-height: 385px;
        }
    }

    .courses-info {
        .block {
            background-size: 100%;
        }
    }

    .courses-page .contact-block h2:before {
        display: none;
    }

    .block-events {
        background: linear-gradient(99.73deg, rgba(243, 243, 243, 0.4) 0.54%, rgba(215, 215, 215, 0.4) 105.77%);

        p {
            margin-right: 15px;
            width: auto;
            min-width: 170px;
        }

        .btn {
            width: auto;
        }
    }

    .contact-page {
        .title-icon:before {
            display: none;
        }
    }

    .page-404 {
        main {
            padding-top: 22vh;
            min-height: 75vh;
        }
    }
    
    .theater .text:before, .theater .text:after, .theater .wrap, .info-block .text-wrap:before, .info-block .text-wrap:after, .info-block .wrap, .courses-info .block:before, .courses-info .block:after, .courses-info .wrap{
        display: none;
    }
}

@media(max-width: 992px) {
    .who-block {
        .text {
            &:before {
                display: none;
            }
        }
    }

    .courses-block {
        .container {
            &:before {
                display: none;
            }
        }
    }

    .blog-page-inside {
        .img-wrap {
            border: 0 !important;
        }
    }

    .info-wrap {
        margin-top: 0;
        border-bottom: 1px solid $gray-300;
    }

    .courses-info {
        .block {
            background: none;
        }
    }

    .block-events {
        width: 100%;
    }

    .contact-main {
        .container {
            max-width: 550px;
        }

        #map {
            min-height: 400px;
            width: 100%;
        }

        .info {
            max-width: 215px;
        }
    }
}

@media(max-width: 767px) {
    .target-block {
        .img {
            min-height: 300px;
        }
    }

    .who-block,
    .editor-block {
        .img-wrap {
            &:before {
                top: -15px;
                left: -10px;
                height: calc(100% + 30px);
            }
        }
    }

    .courses-block {
        img {
            width: 100%;
        }
    }

    .blog-page-inside {

        .like,
        .share {
            border-bottom: 1px solid $gray-300;
        }
    }

    .similar {
        img {
            width: 100%;
        }
    }

    .block-events {

        p {
            width: 140px;
            min-width: 140px;
        }
    }

    .contact-main {
        padding: 107px 0 0;
    }

    .page-404 {
        main {
            padding-top: 27vh;
        }

        h1 {
            font-size: 100px;

            &:before {
                background-size: 95% 80px;
                width: 95%;
                height: 80px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

@media(max-width: 576px) {
    .courses-block {
        .wrap-item {
            &:first-child {
                border-right: 0;
                border-bottom: 1px dashed $orange;
            }
        }

        .item {
            min-height: auto;
        }
    }

    .block-events {

        .btn {
            width: 100%;
        }
    }

    .privacy-page {
        h1 {
            font-size: 28px;
        }
    }
    
    .contact-main{
        .info{
            max-width: 100%;
        }
    }
}
