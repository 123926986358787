body {
    font-family: 'Nunito', sans-serif;
    color: $text;
    background-color: $white;
    letter-spacing: 0.04em;
}

h1,
h2,
h3 {
    font-family: 'Philosopher', sans-serif;
}

pre {
    font-family: 'Nunito', sans-serif;
}

a {
    transition: .25s ease-in-out;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

p.h2,
p.h3,
p.h4,
p.h5,
p.h6 {
    line-height: 1.5;
}

.btn {
    padding: 15px 43px;
    letter-spacing: 0.1em;
}

.font-phil {
    font-family: 'Philosopher', sans-serif;
}

.fs-14 {
    font-size: 14px;
}

path,
circle,
stroke,
svg,
img,
button {
    transition: .25s ease-in-out;
}

:active,
:hover,
:focus,
button:focus {
    outline: 0;
    outline-offset: 0;
}

.bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-start {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: '';
    }
}

.bg-before {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.title-icon {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0px;
        left: -320px;
        width: 268px;
        height: 58px;
        background-image: url("data:image/svg+xml,%3Csvg width='268' height='58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.533C5.47 9.243 21.648.8 34.854.066c13.923-.778 27.14 5.39 37.748 14.04 11.325 8.96 20.509 20.13 32.461 28.437 11.269 7.816 21.648 14.807 35.697 12.587 12.169-1.91 26.912-10.447 24.303-24.614-1.903-10.276-13.342-27.772-25.704-25.941-9.833 1.281-3.51 12.404.546 17.164 9.309 10.791 23.928 16.02 37.919 15.345 10.437-.526 20.441-4.108 29.624-9.04a125.504 125.504 0 005.88-3.375c4.945 5.996 10.995 6.591 16.099 5.664 9.947-1.796 20.088-9.257 30.467-7.518 7.429 1.236 11.075 8.354 5.162 16.512 3.612-8.319-1.071-18.011-19.712-11.054-10.63 3.971-25.283 9.761-32.062-3.375-4.125 4.062-10.824 8.182-15.496 10.482a59.047 59.047 0 01-20.417 5.927c-13.536 1.27-28.895-1.43-39.127-10.505-6.016-5.344-14.812-20.162-6.358-27.658 10.699-9.486 24.075 5.093 28.952 13.824 3.065 5.47 5.389 11.97 4.877 18.308-.593 7.404-5.515 12.588-11.463 16.204a48.384 48.384 0 01-42.123 3.101c-13.445-5.252-25.249-16.26-35.651-26.159C66.449 18.89 56.149 9.724 42.35 6.36a38.588 38.588 0 00-26.548 2.689A52.332 52.332 0 000 21.533z' fill='%23AC8A5A' fill-opacity='.2'/%3E%3C/svg%3E");
    }
}

input,
textarea {
    background: transparent !important;
    text-align: center;
    width: 100%;
    border-radius: 3px;
}

label.error {
    display: none !important;
}

input.error,
textarea.error {
    border: 1px solid $red !important;
}

img {
    opacity: 1;
}

img[data-src] {
    opacity: 0;
}

.gray-sm {
    font-weight: 800;
    font-size: 13px;
    color: #B4B4B4;
}

.item {
    address {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 18px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.74398 0.00458131C4.16777 0.130062 1.19683 2.92016 0.853605 6.4816C0.783483 7.19389 0.82408 7.88403 0.949561 8.54465C0.949561 8.54465 0.960633 8.62215 0.997539 8.76977C1.10826 9.26432 1.27433 9.74409 1.48101 10.1943C2.20068 11.8994 3.86514 14.7522 7.60004 17.8561C7.82886 18.048 8.16471 18.048 8.39721 17.8561C12.1321 14.7559 13.7966 11.9031 14.5199 10.1907C14.7303 9.7404 14.8927 9.26432 15.0034 8.76608C15.0366 8.62215 15.0514 8.54096 15.0514 8.54096C15.1363 8.09808 15.1806 7.64414 15.1806 7.17912C15.1806 3.12684 11.8258 -0.139352 7.74398 0.00458131ZM7.99863 10.7516C6.07213 10.7516 4.511 9.19051 4.511 7.26401C4.511 5.33751 6.07213 3.77638 7.99863 3.77638C9.92513 3.77638 11.4863 5.33751 11.4863 7.26401C11.4863 9.19051 9.92513 10.7516 7.99863 10.7516Z' fill='%23C8AF43' /%3E%3C/svg%3E");
        }

        span {
            padding-left: 25px;
            display: inline-block;
        }
    }
}

.modal-dialog {
    max-width: 940px;

    form {
        max-width: 540px;
    }

    input {
        border: 1px solid $orange;
        border-radius: 3px;
    }

    .dropdown-item {
        cursor: pointer;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: $yellow;
    }

    .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba($yellow, .5)
    }

    #dropdown {
        display: block;
    }

    ::placeholder {
        color: $text;
        opacity: .7;
    }

    :-ms-input-placeholder {
        color: $text;
        opacity: .7;
    }

    ::-ms-input-placeholder {
        color: $text;
        opacity: .7;
    }

    :focus::placeholder {
        color: $text;
        opacity: 1;
    }

    :focus::-ms-input-placeholder {
        color: $text;
        opacity: 1;
    }

    :focus::-ms-input-placeholder {
        color: $text;
        opacity: 1;
    }
}

#dropdown {
    display: none;
}

.slider {
    opacity: 0;
}

.slick-arrow {
    z-index: 10;
    height: 30px;
    width: 30px;

    &:before {
        content: '';
    }
}

.slick-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492 492' width='30'%3E%3Cpath fill='%23C8AF43' d='M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z'/%3E%3C/svg%3E");
}

.slick-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 492.004 492.004' width='30'%3E%3Cpath fill='%23C8AF43' d='M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z'/%3E%3C/svg%3E");
}

@media(max-width: 576px){
    .slick-list{
        margin: auto;
        max-width: 90%;
        .item{
            max-width: 90%;
        }
    }
}
